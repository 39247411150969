import React from 'react'
import { observer } from 'mobx-react-lite'
import { Account } from '~/stores/accounts/types'
import RootStore from '~/stores/root/RootStore'
import { Status } from '~/common/ui/Status'
import { Loading } from '~/common/ui/Loading'
import { Panel } from '~/common/ui/Panels'

const getTableRows = (accounts: Account[]) => {
  return accounts.map((item) => {
    return {
      key: item.id,
      cells: [
        item.name,
        item.awsAccountId,
        item.email,
        item.organisationId,
        <Status key={item.id} status={item.status} />,
      ],
    }
  })
}

const headers = [
  { title: 'Name' },
  { title: 'Account ID' },
  { title: 'Email' },
  { title: 'Organisation Id' },
  { title: 'Status' },
]

const ProvisionBillingRoots = observer(() => {
  const { billingroot } = React.useContext(RootStore)

  React.useEffect(() => {
    billingroot.fetch('USED')
  }, [])

  const accounts = billingroot.inUseList()
  const rows = getTableRows(accounts)

  if (billingroot.isLoading) return <Loading />

  return (
    <Panel>
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.key}>
              {row.cells.map((cell, index) => (
                <td key={index}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  )
})

export default ProvisionBillingRoots
