import { Fragment, ReactChild } from 'react'
import { observer } from 'mobx-react-lite'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'
import { Chip } from '~/common/ui/Chip'
import ComponentTime from './ComponentTime'
import { Component, Deployment } from '~/stores/components/types'
import ComponentVersion from './ComponentVersion'
import { Status } from '~/common/ui/Status'
import classNames from 'classnames'

type Props = RouteComponentProps & {
  children?: ReactChild
  data?: Component
  id?: string
  isLoading: boolean
  active: boolean
  onClick?: (id: string) => void
}

const failing = 'FAILING'

const getUpdates = (data: Component | undefined) => {
  const availableDeployments = data?.availableDeployments ?? []
  const activeDeployment = data?.activeDeployment

  if (activeDeployment) {
    return availableDeployments.filter((deployment: Deployment) =>
      isAfter(
        parseISO(deployment.createdTS),
        parseISO(activeDeployment.createdTS),
      ),
    )
  }
  return []
}

const ChipUpdateAvailable = () => (
  <Chip className="bg-green-400">Update Available</Chip>
)

const ChipLocked = () => <Chip className="bg-amber-300">LOCKED</Chip>

const ComponentListItem = observer((props: Props) => {
  const { data, active, isLoading, onClick } = props

  const updates = getUpdates(data)

  const handleOnClick = () => {
    if (onClick && data) {
      onClick(data.name)
    }
  }
  return (
    <button
      className={classNames({
        't-component-list-item': true,
        'border-b border-l-4 border-slate-200 block w-full px-3 pt-4 pb-2': true,
        'opacity-050': isLoading,
        'cursor-pointer': !isLoading,
        'bg-slate-100 border-l-sky-500': active,
        'border-l-sky-100': !active,
      })}
      onClick={handleOnClick}
      disabled={isLoading}
      type="button"
    >
      <div className="text-left">
        {isLoading && !data && <div>Loading...</div>}
        {
          <Fragment>
            {data && data.name && (
              <div className="font-semibold">{data.name}</div>
            )}
            {data && data.activeDeployment ? (
              <ComponentVersion
                type="GitHash"
                version={data.activeDeployment.version}
              />
            ) : isLoading ? (
              <div>Loading...</div>
            ) : (
              <div>No active deployment</div>
            )}
            {data && data.activeDeployment && (
              <ComponentTime
                className="text-xs"
                time={data.activeDeployment.modifiedTS}
              />
            )}
            <div className="pt-2">
              {updates.length > 0 && <ChipUpdateAvailable />}
              {data && !data.deployPassing && <Status status={failing} />}
              {data && data.locked && <ChipLocked />}
            </div>
          </Fragment>
        }
      </div>
    </button>
  )
})

export default withRouter(ComponentListItem)
