import useSWR from 'swr'

// axios.defaults.baseURL = 'https://adminapi.dev-master.core.dev.juma.cloud/'

export function useOrganisationalUnits(organisationId: string) {
  const { data, error } = useSWR({
    url: `/organisation/${organisationId || ''}/organisational-units`,
  })

  return {
    organisational_units: data?.OrganisationalUnits as OrganisationalUnit[],
    loading: !error && !data,
    error: error,
  }
}

export type OrganisationalUnit = {
  Id: string
  OrganisationId: string
  Status: string
  Name: string
  ParentOrganisationalUnitId: string
  OrganisationalUnitType: string
  Tags: Record<string, string>
  CreatedBy: string | null
  CreatedTS: string
  ModifiedTS: string
  UserTaskId: string
}
