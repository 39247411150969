import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { Account, BillingRoot } from '~/stores/accounts/types'
import { AccountApi } from '~/stores/accounts/AccountApi'
import { RootStore } from '~/stores/root/RootStore'
import { Status } from '~/common/api/apiLegacy'

/* BillingRootStore queries the API for a list of all AWS Accounts which are
 * marked as billing roots for the Stax Installation.
 */

export default class BillingRootStore {
  root: RootStore
  api: AccountApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: AccountApi, endpoint = '/accounts') {
    makeObservable(this, {
      status: observable,
      inUseAccounts: observable,
      poolAccounts: observable,
      fetch: action,
      isLoading: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  status: Status = Status.Idle

  inUseAccounts: ObservableMap<BillingRoot, Account[]> = observable.map()

  poolAccounts: ObservableMap<BillingRoot, Account[]> = observable.map()

  fetch = async (type: string, force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(
        `${this.endpoint}?billing=${type}`,
        force,
      )
      if (type === 'USED') {
        this.inUseAccounts.set('BillingRoots', res.data.accounts)
      } else if (type === 'UNUSED') {
        this.poolAccounts.set('BillingRoots', res.data.accounts)
      }
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  inUseList = () => {
    return this.inUseAccounts.get('BillingRoots') || []
  }
  poolList = () => {
    return this.poolAccounts.get('BillingRoots') || []
  }
}
