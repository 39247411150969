import { CUSTOMER_SUPPORT_PLANS } from '~/common/constants'

export enum FIELDS {
  INTERNAL_CUSTOMER = 'internalCustomer',
  MARKETPLACE_SIGNUP = 'marketplaceSignup',
  PARTNER_SUPPORT = 'partnerSupport',
  CUSTOMER_TYPE = 'customerType',
  AWS_ACCOUNT_ID = 'awsAccountId',
  AWS_SUPPORT_TYPE = 'awsSupportType',
  AWS_ACCOUNT_EMAIL = 'awsAccountEmail',
  CUSTOMER_SUPPORT_PLAN = 'customerSupportPlan',
  BILLING_ROOT = 'billingRoot',
  OWNER_NAME = 'ownerName',
  COMPANY_NAME = 'companyName',
  STAX_ALIAS = 'staxAlias',
  EMAIL_TEMPLATE = 'emailTemplate',
  ROOT_EMAIL = 'rootEmail',
  SALESFORCE_ID = 'salesforceId',
  SPOTLIGHT_ID = 'spotlightId',
  ORG_ALIAS = 'orgAlias',
  CUSTOMER = 'customerId',
  CUSTOMER_NAME = 'customerName',
  IDAM_USER_EMAIL = 'idamUserEmail',
  IDAM_USER_FIRST_NAME = 'idamUserFirstName',
  IDAM_USER_LAST_NAME = 'idamUserLastName',
}

export enum CUSTOMER_TYPES {
  DIRECT = 'DIRECT',
  ECAM = 'ECAM',
}

export enum AWS_SUPPORT_TYPES {
  BASIC = 'BASIC',
  DEVELOPER = 'DEVELOPER',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
}

export const FIELD_HELPER_TEXTS = {
  [FIELDS.CUSTOMER_TYPE]: null,
  [FIELDS.AWS_SUPPORT_TYPE]: 'AWS support type for this customer',
  [FIELDS.AWS_ACCOUNT_ID]: '',
  [FIELDS.AWS_ACCOUNT_EMAIL]: 'Organisation management account email',
  [FIELDS.CUSTOMER_SUPPORT_PLAN]: 'Stax support plan for this customer',
  [FIELDS.BILLING_ROOT]: null,
  [FIELDS.CUSTOMER]: 'Customer in which to create the organisation',
  [FIELDS.CUSTOMER_NAME]: '',
  [FIELDS.OWNER_NAME]: 'Name of owner for the Stax account',
  [FIELDS.COMPANY_NAME]: 'Name of the company for the Stax Account',
  [FIELDS.STAX_ALIAS]:
    'Stax alias for the customer, e.g. company or company-name',
  [FIELDS.EMAIL_TEMPLATE]:
    'Direct and ECAM customers can utilise a template. Template must conform with either CompanyName+${Stax::AccountId}@CompanyDomain.com or ${Stax::AccountId}@CompanyDomain.com',
  [FIELDS.ROOT_EMAIL]: 'Email address for the root cognito user',
  [FIELDS.SALESFORCE_ID]: 'Salesforce identifier for this customer',
  [FIELDS.SPOTLIGHT_ID]:
    "Stax Spotlight customer ID if they're already a Spotlight customer",
  [FIELDS.ORG_ALIAS]: 'Alias for the organisation, shown in customer console',
  [FIELDS.IDAM_USER_EMAIL]: 'Email address of the first IDAM user',
  [FIELDS.IDAM_USER_FIRST_NAME]: 'First name of the first IDAM user',
  [FIELDS.IDAM_USER_LAST_NAME]: 'Last name of the first IDAM user',
}

export const CUSTOMER_FORM_INITIAL_STATE = {
  partnerSupport: false,
  internalCustomer: false,
  marketplaceSignup: false,
  customerType: CUSTOMER_TYPES.DIRECT,
  awsSupportType: AWS_SUPPORT_TYPES.BASIC,
  customerSupportPlan: CUSTOMER_SUPPORT_PLANS.STARTER,
  billingRoot: '',
  ownerName: '',
  companyName: '',
  staxAlias: '',
  emailTemplate: '',
  rootEmail: '',
  salesforceId: '',
  spotlightId: '',
  idamUserEmail: '',
  idamUserFirstName: '',
  idamUserLastName: '',
}

export const ORG_FORM_INITIAL_STATE = {
  marketplaceSignup: false,
  customerId: '',
  billingRoot: '',
  orgAlias: '',
  emailTemplate: '',
  awsSupportType: AWS_SUPPORT_TYPES.BASIC,
  spotlightId: '',
  salesforceId: '',
  idamUserEmail: '',
  idamUserFirstName: '',
  idamUserLastName: '',
  partnerSupport: false,
}

export const VERIFICATION_FORM_INITIAL_STATE = {
  awsAccountId: '',
  customerName: '',
  awsAccountEmail: '',
}
