import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { formatDistance, parseISO } from 'date-fns'

type Props = {
  time: string
  className?: string
  isNew?: boolean
}

const ComponentTime = observer((props: Props) => {
  const { time } = props
  const parsedTime = formatDistance(parseISO(time), new Date())

  return (
    <div className={classNames('text-slate-500', props.className)}>
      {props.isNew ? 'Merged' : 'Last deployed'} {parsedTime} ago
    </div>
  )
})

export default ComponentTime
