import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps, Route, Redirect } from 'react-router'
import { observer } from 'mobx-react-lite'
import Auth from '@aws-amplify/auth'
import { AuthConfig } from '~/common/config/types'
import { HocConsumerProps } from '~/wrappers/AmplifyListener.d'
import { withAmplifyAuth } from '~/wrappers/AmplifyListener'
import PageLoginProcess from '~/pages/PageLoginProcess'
import { routes } from '~/routes'
import PageLogin from '~/pages/PageLogin'
import PageSignOut from '~/pages/PageSignOut'
import { BusyFullWidth } from '~/common/ui/Loading'

type AmplifyProps = RouteComponentProps &
  HocConsumerProps & {
    children?: any
    config: AuthConfig
    history: RouteComponentProps['history']
    match: RouteComponentProps['match']
    location: RouteComponentProps['location']
  }

export const isAuthPage = (path: string) => {
  let cleanedPath = path
  if (path.endsWith('/')) {
    cleanedPath = path.substr(0, path.length - 1)
  }
  return (
    cleanedPath !== '/' &&
    Object.values({ ...routes.auth }).findIndex(
      (route) =>
        route.indexOf(cleanedPath) > -1 && cleanedPath.indexOf(route) > -1,
    ) > -1
  )
}

const AuthWrapper = observer((props: AmplifyProps) => {
  const currentRoute = props.history.location.pathname
  const loadingRedirect = () => props.history.push(routes.auth.LOADING)
  const loadingRedirectComponent = () => <BusyFullWidth />
  const { authState, config } = props

  React.useEffect(() => {
    Auth.configure({ Auth: config })
    props.fetchUserSession()
  }, [])

  React.useEffect(() => {
    if (
      !authState.loggedIn &&
      !authState.isLoading &&
      !isAuthPage(currentRoute)
    ) {
      console.debug('Protected page, redirecting....')
      props.history.push(routes.auth.LOGIN)
    }
  }, [currentRoute, authState.loggedIn, authState.isLoading])

  const signoutComponent = () => {
    if (authState.loggedIn) {
      return <PageSignOut signOut={props.logout} redirect={loadingRedirect} />
    } else {
      return <Redirect to={routes.auth.LOGIN} />
    }
  }

  const loginComponent = () => {
    return <PageLogin redirect={loadingRedirect} />
  }

  if (
    currentRoute !== routes.auth.SIGN_OUT &&
    currentRoute !== routes.auth.PROCESS_SIGN_IN &&
    authState.isLoading
  ) {
    return <BusyFullWidth />
  }

  return (
    <Fragment>
      <Route path={routes.auth.SIGN_OUT} component={signoutComponent} />
      <Route
        path={routes.auth.PROCESS_SIGN_IN}
        component={PageLoginProcess}
        exact
      />
      <Route path={routes.auth.LOGIN} component={loginComponent} exact />
      <Route
        path={routes.auth.LOADING}
        component={loadingRedirectComponent}
        exact
      />
      {authState.loggedIn && props.children}
    </Fragment>
  )
})

// @ts-ignore
export const RouterAuthWrapper = withRouter(withAmplifyAuth(AuthWrapper))
