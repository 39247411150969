import React from 'react'
import { observer } from 'mobx-react-lite'
import { ButtonOutline } from '~/common/ui/Buttons'
import { useHistory, useRouteMatch } from 'react-router'
import RootStore from '~/stores/root/RootStore'
import FactoryButton from '~/common/ui/FactoryButton'
import NoResults from '~/common/ui/NoResults'
import Can, { Role } from '~/common/ui/Can'
import LocalUserInviteSwitch from './CustomerAndOrgDetails/LocalUserInviteSwitch'
import OrganisationStore from '~/stores/organisations/OrganisationStore'
import { ClickToCopy } from '~/common/ui/ClickToCopy'
import { CUSTOMER_SUPPORT_PLANS } from '~/common/constants'
import { Customer } from '~/stores/customers/types'
import { Panel } from '~/common/ui/Panels'
import classNames from 'classnames'
import { Select } from '~/common/ui/Forms'
import { routes } from '../routes'

type Props = {
  customerId: string
  organisationId?: string
}

const fetchData = async (
  customerId: string,
  store: OrganisationStore,
  organisationId?: string,
) => {
  await store.fetch(customerId)
  if (organisationId) {
    store.setCurrent(customerId, organisationId)
  } else {
    store.setDefaultOrg(customerId)
  }
}

export const getCustomerSupportPlan = (
  customer: Customer | undefined,
): CUSTOMER_SUPPORT_PLANS => {
  const plan = Object.values(CUSTOMER_SUPPORT_PLANS).find(
    (x) => x === customer?.supportPlan,
  )
  return plan || CUSTOMER_SUPPORT_PLANS.STARTER
}

type JustChildren = React.PropsWithChildren<{ className?: string }>

const Label = (props: JustChildren) => (
  <div className="text-slate-500 pr-4">{props.children}</div>
)

const Value = (props: JustChildren) => <div>{props.children}</div>

const CellLabel = (props: JustChildren) => (
  <td className={classNames('text-slate-500 pr-6 py-1', props.className)}>
    {props.children}
  </td>
)

const CellValue = (props: JustChildren) => (
  <td className="pb-1">{props.children}</td>
)

const PanelWithSpace = (props: JustChildren) => (
  <Panel className="p-4 mb-3">{props.children}</Panel>
)

type SelectOrgProps = React.PropsWithChildren<{
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: string
}>

const SelectOrg = (props: SelectOrgProps) => {
  return (
    <Select
      className="w-60"
      name="organization"
      onChange={props.onChange}
      value={props.value}
    >
      {props.children}
    </Select>
  )
}

const CustomerDetails = ({ customer }: { customer: Customer | undefined }) => {
  return (
    <PanelWithSpace>
      <details>
        <summary
          aria-controls="panel-customer-details-content"
          id="panel-customer-details-header"
          className="font-semibold text-lg"
        >
          Customer Details
        </summary>
        <table className="mt-2">
          <tbody>
            <tr>
              <CellLabel>Customer Name</CellLabel>
              <CellValue>{customer?.name || '—'}</CellValue>
            </tr>
            <tr>
              <CellLabel>Company Name</CellLabel>
              <CellValue>{customer?.companyName}</CellValue>
            </tr>
            <tr>
              <CellLabel>Customer ID</CellLabel>
              <CellValue>
                {customer?.id || '—'} <ClickToCopy text={customer?.id} />
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Customer Email</CellLabel>
              <CellValue>
                {customer?.email || '—'} <ClickToCopy text={customer?.email} />
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Status</CellLabel>
              <CellValue>{customer?.status || '—'}</CellValue>
            </tr>
            <tr>
              <CellLabel>Support Plan</CellLabel>
              <CellValue>{getCustomerSupportPlan(customer)}</CellValue>
            </tr>
            <tr>
              <CellLabel>Type</CellLabel>
              <CellValue>
                <a
                  href="https://stax.atlassian.net/wiki/spaces/JUMA/pages/551387149/Stax+Customer+Types+Summary"
                  rel="noreferrer"
                  target="_blank"
                >
                  {customer?.type || '—'}
                </a>
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Internal Customer</CellLabel>
              <CellValue>
                {customer?.internal ? 'Internal' : 'External'}
              </CellValue>
            </tr>
            <tr>
              <CellLabel>Factory Version</CellLabel>
              <CellValue>{customer?.factoryVersion}</CellValue>
            </tr>
          </tbody>
        </table>
      </details>
    </PanelWithSpace>
  )
}

const PanelTitle = (props: JustChildren) => (
  <div className="text-lg pb-2">{props.children}</div>
)

const OrganizationTitle = () => <PanelTitle>Organisation</PanelTitle>

const CustomerAndOrgDetails = observer(
  ({ customerId, organisationId }: Props) => {
    const {
      account: accountStore,
      customer: customerStore,
      organisation,
      billingroot,
    } = React.useContext(RootStore)

    React.useEffect(() => {
      customerStore.fetch()

      billingroot.fetch('UNUSED')

      fetchData(customerId, organisation, organisationId)

      return () => organisation.reset()
    }, [])

    const history = useHistory()
    const match = useRouteMatch<{ id?: string; orgId?: string }>()

    const handleChangeOrg = (event: React.ChangeEvent<HTMLSelectElement>) => {
      organisation.setCurrent(customerId, event.currentTarget.value as string)
      history.push(`${routes.customer.INDEX}/${customerId}`)
    }

    const customer = customerStore.active

    const runSaga = () => organisation.invokeSaga(organisation.current!.id)

    const runOrgValidation = () =>
      organisation.runOrgValidation(organisation.current!.id)

    const runAllAccountsSaga = () =>
      organisation.runAllAccountsSaga(organisation.current!.id)

    if (organisation.isLoading) return <span>Loading...</span>

    if (!organisation.current) {
      const orgs = organisation.list(customerId).map((org) => (
        <option value={org.id} key={org.id}>
          {org.name}
        </option>
      ))
      orgs.splice(
        0,
        0,
        <option value={'none'} key={'none'}>
          {'No org selected'}
        </option>,
      )

      return (
        <React.Fragment>
          <CustomerDetails customer={customer} />

          <PanelWithSpace>
            <div>
              <OrganizationTitle />
              <SelectOrg onChange={handleChangeOrg} value={'No Org selected'} />
            </div>
            <div>
              <NoResults title="No organisation selected" />
            </div>
          </PanelWithSpace>
        </React.Fragment>
      )
    }
    const accounts = accountStore.list(organisation.current.id)

    let hasExternalBillingRoot = false

    for (let i = 0; i < accounts.length; i++) {
      if (!accounts[i].billingAccountId && accounts[i].origin === 'EXTERNAL') {
        hasExternalBillingRoot = true
        break
      }
    }

    const numDiscoveredAccounts = accounts.reduce((currCount, account) => {
      if (account.status === 'DISCOVERED') return currCount + 1
      return currCount
    }, 0)

    const numActiveAccounts = accounts.reduce((currCount, account) => {
      if (account.status === 'ACTIVE') return currCount + 1
      return currCount
    }, 0)

    const numActiveBillingRoots = billingroot.poolList().length

    const enableLocalUserInvite: string =
      organisation
        .listConfig(organisation.current.id)
        .find((config) => config.key === 'EnableLocalUserInvite')?.value || ''

    return (
      <React.Fragment>
        <CustomerDetails customer={customer} />

        <PanelWithSpace>
          <OrganizationTitle />
          <div className="flex">
            <SelectOrg
              onChange={handleChangeOrg}
              value={organisation.current && organisation.current.id}
            >
              {organisation.list(customerId).map((org) => (
                <option value={org.id} key={org.id}>
                  {org.name}
                </option>
              ))}
            </SelectOrg>

            <Can
              requiredRole={Role.deployer}
              yes={
                <div className="pl-2">
                  {customer && customer.email && (
                    <React.Fragment>
                      <ButtonOutline
                        title={
                          numActiveBillingRoots === 0
                            ? 'No available billing roots'
                            : 'Create an organisation'
                        }
                        onClick={() =>
                          numActiveBillingRoots > 0 &&
                          history.push({
                            pathname: routes.provision.ADDORG,
                            state: {
                              customerName: customer && customer.name,
                              customerId: match.params.id,
                            },
                          })
                        }
                        disabled={numActiveBillingRoots === 0}
                      >
                        + Add Organisation
                      </ButtonOutline>
                    </React.Fragment>
                  )}
                </div>
              }
            />
          </div>
        </PanelWithSpace>

        <PanelWithSpace>
          <details>
            <summary className="font-semibold text-lg">
              Organisation Details
            </summary>
            <div className="grid grid-cols-4">
              <div className="col-span-3">
                <table>
                  <tr>
                    <CellLabel>Name</CellLabel>
                    <CellValue>{organisation.current.name}</CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Alias</CellLabel>
                    <CellValue>{organisation.current.alias}</CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Org Id</CellLabel>
                    <CellValue>
                      {organisation.current.id}{' '}
                      <ClickToCopy text={organisation.current.id} />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Billing Account ID</CellLabel>
                    <CellValue>
                      {organisation.current.billingAccountId}{' '}
                      <ClickToCopy
                        text={organisation.current.billingAccountId}
                      />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Salesforce ID</CellLabel>
                    <CellValue>
                      {organisation.current.salesforceId || '—'}{' '}
                      <ClickToCopy text={organisation.current.salesforceId} />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Spotlight ID</CellLabel>
                    <CellValue>
                      {organisation.current.externalStaxId}{' '}
                      <ClickToCopy text={organisation.current.externalStaxId} />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Status</CellLabel>
                    <CellValue>{organisation.current.status}</CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Compliance Type</CellLabel>
                    <CellValue>
                      <span
                        style={
                          organisation.current.complianceType
                            ? {
                                color: 'red',
                              }
                            : {}
                        }
                      >
                        {organisation.current.complianceType || 'None'}
                      </span>
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>AWS Support Plan</CellLabel>
                    <CellValue>{organisation.current.awsSupportType}</CellValue>
                  </tr>
                  <tr>
                    <CellLabel>AWS Partner Support</CellLabel>
                    <CellValue>
                      {organisation.current.awsPartnerSupport ? 'Yes' : 'No'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>External Billing Root</CellLabel>
                    <CellValue>
                      {hasExternalBillingRoot ? 'Yes' : 'No'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Factory Version</CellLabel>
                    <CellValue>
                      {organisation.current.factoryVersion}{' '}
                      <ClickToCopy text={organisation.current.factoryVersion} />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Allowed domains</CellLabel>
                    <CellValue>
                      {organisation.current.allowedDomains || '—'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Compliance Type</CellLabel>
                    <CellValue>
                      {organisation.current.complianceType || '—'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>Marketplace Enabled?</CellLabel>
                    <CellValue>
                      {organisation.current.marketplaceSignup ? 'Yes' : 'No'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel>AWS Partner Support?</CellLabel>
                    <CellValue>
                      {organisation.current.awsPartnerSupport ? 'Yes' : 'No'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel className="text-nowrap">
                      AWS Email Template
                    </CellLabel>
                    <CellValue>
                      {organisation.current.awsAccountEmailTemplate || '—'}{' '}
                      <ClickToCopy
                        text={organisation.current.awsAccountEmailTemplate}
                      />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel className="text-nowrap">
                      Local User Invite Enabled?
                    </CellLabel>

                    <CellValue>
                      <LocalUserInviteSwitch
                        enabled={
                          enableLocalUserInvite.toLowerCase() !== 'false'
                        }
                        orgId={organisation.current.id}
                      />
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel className="align-top">Regions</CellLabel>
                    <CellValue>
                      {organisation.current.regions.join(', ') || '—'}
                    </CellValue>
                  </tr>
                  <tr>
                    <CellLabel className="align-top">Features</CellLabel>
                    <CellValue>
                      <ul>
                        {Object.entries(
                          organisation.current.features ?? {},
                        ).map(([feature, value]) => (
                          <li key={feature} className="py-1">
                            <span>{feature}</span>
                            <span className="ml-2 text-slate-500 font-semibold">
                              {value.toString()}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </CellValue>
                  </tr>
                </table>
              </div>
              <div>
                <div className="pb-4 space-y-2">
                  <div>
                    <Label>Org Factory Version</Label>
                    <Value>{organisation.current.factoryVersion}</Value>
                  </div>

                  <div title="Updates the Organization and runs Account Assurance over the Management, Security and Logging Accounts">
                    <Can
                      requiredRole={Role.deployer}
                      yes={
                        <FactoryButton onClick={runSaga}>
                          Run Org Saga
                        </FactoryButton>
                      }
                      no={<FactoryButton disabled>Run Org Saga</FactoryButton>}
                    />
                  </div>

                  <div title="Run Org Validation">
                    <Can
                      requiredRole={Role.deployer}
                      yes={
                        <FactoryButton onClick={runOrgValidation}>
                          Run Org Validation
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton disabled>
                          Run Org Validation
                        </FactoryButton>
                      }
                    />
                  </div>
                </div>

                <div className="pb-4 space-y-2">
                  <div>
                    <Label>Stax Accounts</Label>
                    <Value>{`${numActiveAccounts} active accounts`}</Value>
                  </div>

                  <div title="Runs Account Assurance over all active Accounts (except for the Management, Security and Logging Accounts). If you are running this for multiple Orgs, it is best to only run 5 Orgs at a time.">
                    <Can
                      requiredRole={Role.deployer}
                      yes={
                        <FactoryButton onClick={runAllAccountsSaga}>
                          Run All Accounts Saga
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={runAllAccountsSaga}
                        >
                          Run All Accounts Saga
                        </FactoryButton>
                      }
                    />
                  </div>
                </div>

                <div className="pb-4 space-y-2">
                  <div>
                    <Label>Stax account discovery</Label>
                    <Value>{`${numDiscoveredAccounts} discovered accounts`}</Value>
                  </div>

                  <div title="Checks if there are any Accounts within the Organization that have not been onboarded to Stax and presents them to the customer as 'Discovered' within the Stax Console">
                    <Can
                      requiredRole={Role.customersupport}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.rediscoverAllAccounts(
                              organisation.current!.id,
                            )
                          }
                        >
                          Run discovery
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.rediscoverAllAccounts(
                              organisation.current!.id,
                            )
                          }
                        >
                          Run discovery
                        </FactoryButton>
                      }
                    />
                  </div>
                </div>

                <div className="pb-4 space-y-2">
                  <div>
                    <Label>Stax Policies</Label>
                  </div>

                  <div title="Applys the Stax Policies to the customers AWS Organization">
                    <Can
                      requiredRole={Role.admin}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.runApplyStaxPolicies(
                              organisation.current!.id,
                            )
                          }
                        >
                          Apply Stax Policies
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.runApplyStaxPolicies(
                              organisation.current!.id,
                            )
                          }
                        >
                          Apply Stax Policies
                        </FactoryButton>
                      }
                    />
                  </div>

                  <div title="Syncs the customers policies from AWS to Stax">
                    <Can
                      requiredRole={Role.admin}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.runPolicySync(organisation.current!.id)
                          }
                        >
                          Sync AWS Policies to Stax
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.runPolicySync(organisation.current!.id)
                          }
                        >
                          Sync AWS Policies to Stax
                        </FactoryButton>
                      }
                    />
                  </div>
                </div>

                <div className="pb-4 space-y-2">
                  <div>
                    <Label>Stax Organisational Units</Label>
                  </div>

                  <div title="Syncs the customers OUs from AWS to Stax">
                    <Can
                      requiredRole={Role.admin}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.runOrganisationalUnitSync(
                              organisation.current!.id,
                            )
                          }
                        >
                          Sync AWS OUs to Stax
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.runOrganisationalUnitSync(
                              organisation.current!.id,
                            )
                          }
                        >
                          Sync AWS OUs to Stax
                        </FactoryButton>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </details>
        </PanelWithSpace>
      </React.Fragment>
    )
  },
)

export default CustomerAndOrgDetails
