import React from 'react'
import * as RadixToast from '@radix-ui/react-toast'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'

export type Variant = 'success' | 'warning' | 'error' | 'info'

export type Props = {
  action?: React.ReactNode
  message: React.ReactNode
  open: boolean
  variant?: Variant
}

function Toast(props: Props) {
  const { open = false, message, action } = props

  return (
    <RadixToast.Provider>
      <RadixToast.Root open={open} className="bg-red-100 rounded shadow-md p-2">
        <RadixToast.Description className="flex pb-2">
          <ExclamationTriangleIcon className="w-4 fill-red-800" />
          {message}
        </RadixToast.Description>

        <RadixToast.Close>{action}</RadixToast.Close>
      </RadixToast.Root>

      <RadixToast.Viewport />
    </RadixToast.Provider>
  )
}

export default Toast
