import React from 'react'
import RootStore from '~/stores/root/RootStore'
import { Status } from '~/stores/components/types'
import { ButtonOutline } from '~/common/ui/Buttons'

const IDLE_TIMEOUT = 10000

type Props = {
  userId: string
  organisationId: string
  disabled: boolean
  text: string
}

const ComponentSendVerifyEmail = ({
  userId,
  organisationId,
  disabled,
  text,
  ...restProps
}: Props) => {
  const { user: userStore } = React.useContext(RootStore)
  const [status, setStatus] = React.useState<Status>('IDLE')

  const handleSendVerifyEmail = (userId: string) => {
    setStatus('PENDING')
    userStore
      .sendVerifyEmail(organisationId, userId)
      .then(() => setStatus('SUCCESS'))
      .catch(() => setStatus('ERROR'))
      .finally(() => setTimeout(() => setStatus('IDLE'), IDLE_TIMEOUT))
  }

  if (status === 'PENDING') {
    return <ButtonOutline disabled={true}>Loading...</ButtonOutline>
  }

  return (
    <ButtonOutline
      onClick={() => handleSendVerifyEmail(userId)}
      disabled={disabled}
    >
      {status === 'ERROR' ? 'Error!' : text}
    </ButtonOutline>
  )
}

export default ComponentSendVerifyEmail
