import useSWR from 'swr'

// axios.defaults.baseURL = 'https://adminapi.dev-master.core.dev.juma.cloud/'

export function usePolicies(organisationId: string) {
  const { data: policy_data, error: policy_error } = useSWR({
    url: `/organisation/${organisationId || ''}/policies`,
  })

  const { data: policy_attachments_data, error: policy_attachments_error } =
    useSWR({
      url: `/organisation/${organisationId || ''}/policies/attachments`,
    })

  return {
    policies: policy_data?.Policies as Policy[],
    policy_attachments:
      policy_attachments_data?.PolicyAttachments as PolicyAttachment[],
    loading:
      !policy_error &&
      !policy_attachments_error &&
      !policy_data &&
      !policy_attachments_data,
    error: policy_error || policy_attachments_error,
  }
}

export type Policy = {
  Id: string
  OrganisationId: string
  Status: string
  Name: string
  Description: string
  PolicyType: string
  Content: string
  PolicyOwner: string
  AwsId: string
  ExternalResource: boolean
  OrganisationAttachment: Record<string, any> | null
  Tags: Record<string, string>
  CreatedBy: string | null
  CreatedTS: string
  ModifiedTS: string
  UserTaskId: string
}

export type PolicyAttachment = {
  Id: string
  OrganisationId: string
  Status: string
  PolicyId: string
  OrganisationalUnitId: string | null
  AccountId: string | null
  PolicyAttachmentType: string
  TargetAwsId: string
  CreatedBy: string | null
  CreatedTS: string
  ModifiedTS: string
  UserTaskId: string
}
