import { observer } from 'mobx-react-lite'

type Props = {
  name?: string
  company?: string | null
  region?: string
  orgId?: string
  userId?: string
  roles?: string[]
  customerId?: string
  initials?: string
}

const PopupMenu = (props: Props) => {
  return (
    <div
      className="displayOnParentFocusWithin bg-white p-5 space-y-4 absolute text-left shadow-md"
      style={{
        width: '20rem',
        right: '2rem',
      }}
    >
      <div>
        <div className="font-semibold">{props.name}</div>
        <div color="textSecondary">{props.company}</div>
      </div>

      <div>
        <div className="font-semibold">Roles</div>
        <div>{props.roles && props.roles.join(', ')}</div>
      </div>

      <div>
        <div className="font-semibold">Region</div>
        <div>{props.region}</div>
      </div>

      {props.userId && (
        <div>
          <div className="font-semibold">User ID</div>
          <div>{props.userId}</div>
        </div>
      )}

      {props.orgId && (
        <div>
          <div className="font-semibold">Organistation ID</div>
          <div>{props.orgId}</div>
        </div>
      )}

      {props.customerId && (
        <div>
          <div className="font-semibold">Customer ID</div>
          <div>{props.customerId}</div>
        </div>
      )}
    </div>
  )
}

const NavAccountMenu = observer((props: Props) => {
  return (
    // biome-ignore lint/a11y/noNoninteractiveTabindex: we want this clickable, so the popup menu can be shown
    <div tabIndex={0}>
      <div className="cursor-pointer bg-teal-200 rounded-full p-3 font-semibold inline-block">
        {props.initials ?? ''}
      </div>

      <PopupMenu {...props} />
    </div>
  )
})

export default NavAccountMenu
