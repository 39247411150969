import { createContext } from 'react'

import AuthStore from '~/stores/auth/AuthStore'
import CustomerStore from '~/stores/customers/CustomerStore'
import ComponentStore from '~/stores/components/ComponentStore'
import NavigationStore from '~/stores/navigation/NavigationStore'
import OrganisationStore from '~/stores/organisations/OrganisationStore'
import WorkloadStore from '~/stores/workloads/WorkloadStore'
import AccountStore from '~/stores/accounts/AccountStore'
import CatalogueStore from '~/stores/catalogue/CatalogueStore'
import ReleaseStore from '~/stores/releases/ReleaseStore'
import BillingRootStore from '~/stores/provision/BillingRootStore'
import ProvisionStore from '~/stores/provision/ProvisionStore'
import UserStore from '~/stores/users/UserStore'
import AccountService from '~/stores/accounts/AccountApi'
import CustomerService from '~/stores/customers/CustomerApi'
import OrganisationService from '~/stores/organisations/OrganisationApi'
import WorkloadService from '~/stores/workloads/WorkloadApi'
import ComponentService from '~/stores/components/ComponentApi'
import CatalogueService from '~/stores/catalogue/CatalogueApi'
import ProvisionService from '~/stores/provision/ProvisionApi'
import UserService from '~/stores/users/UserApi'

export class RootStore {
  constructor() {
    this.account = new AccountStore(this, new AccountService())
    this.navigation = new NavigationStore()
    this.auth = new AuthStore(this)
    this.customer = new CustomerStore(this, new CustomerService())
    this.component = new ComponentStore(this, new ComponentService())
    this.catalogue = new CatalogueStore(this, new CatalogueService())
    this.workload = new WorkloadStore(this, new WorkloadService())
    this.organisation = new OrganisationStore(this, new OrganisationService())
    this.release = new ReleaseStore(this, new ComponentService())
    this.billingroot = new BillingRootStore(this, new AccountService())
    this.provision = new ProvisionStore(this, new ProvisionService())
    this.user = new UserStore(this, new UserService())
  }

  account: AccountStore
  billingroot: BillingRootStore
  navigation: NavigationStore
  auth: AuthStore
  catalogue: CatalogueStore
  customer: CustomerStore
  component: ComponentStore
  workload: WorkloadStore
  organisation: OrganisationStore
  release: ReleaseStore
  provision: ProvisionStore
  user: UserStore
}

export default createContext(new RootStore())
