import { useCallback, useEffect, useState } from 'react'

/**
 * Simple usage:

  const TextCopy = props => {
    const [copied, copy] = useCopyToClipboard('Lorem ipsum');
    return (
      <div>
        <button onClick={copy}>Click to copy</button>
        <span>{copied && 'Copied!'}</span>
      </div>
    );
  };
 */
export const useCopyToClipboard = (
  text: string,
  clearAfterTimeout: number = 3000,
): [boolean, () => void] => {
  const copyToClipboard = (value: string) => {
    if (!document) return false

    const element = document.createElement('textarea')
    element.value = value
    element.setAttribute('readonly', '')
    element.style.position = 'absolute'
    element.style.left = '-9999px'
    document.body.appendChild(element)
    const selected =
      (document.getSelection()?.rangeCount || -1) > 0
        ? document.getSelection()?.getRangeAt(0)
        : false
    element.select()

    const success = document.execCommand('copy')

    document.body.removeChild(element)

    if (selected) {
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(selected)
    }

    return success
  }

  const [copied, setCopied] = useState(false)

  const copy = useCallback(() => {
    let timer: number | undefined

    if (!copied) {
      setCopied(copyToClipboard(text))
      timer = window.setTimeout(() => setCopied(false), clearAfterTimeout)
    }

    return () => timer && window.clearTimeout(timer)
  }, [text, copied])

  useEffect(() => () => setCopied(false), [text])

  return [copied, copy]
}

/**
 * https://usehooks.com/useAsync/
 * @param asyncFunction
 * @param immediate
 * @returns
 */
export const useAsync = <T, E = string>(
  asyncFunction: () => Promise<T>,
  immediate = true,
) => {
  const [status, setStatus] = useState<
    'idle' | 'pending' | 'success' | 'error'
  >('idle')
  const [value, setValue] = useState<T | null>(null)
  const [error, setError] = useState<E | null>(null)

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus('pending')
    setValue(null)
    setError(null)

    return asyncFunction()
      .then((response: any) => {
        setValue(response)
        setStatus('success')
      })
      .catch((error: any) => {
        setError(error)
        setStatus('error')
      })
  }, [asyncFunction])

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])

  return { execute, status, value, error }
}
