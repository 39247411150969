import { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Amplify from '@aws-amplify/core'
import { init as initSentry } from '@sentry/browser'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import config from '~/common/config'
import { RouterAuthWrapper } from '~/wrappers/AuthWrapper'
import { AmplifyAuthProvider } from '~/wrappers/AmplifyListener'
import PageCustomers from '~/pages/PageCustomers'
import PageComponents from '~/pages/PageComponents'
import PageShowcase from '~/pages/PageShowcase'
import PageProvision from '~/pages/PageProvision'
import { routes } from '~/routes'
import AuthHelper from '~/widgets/AuthHelper'

import './app.css'
import { SWRConfig } from 'swr'
import { fetcher } from '~/common/api/apiFetchers'

Amplify.configure({
  API: window.AWS_CONFIG.API,
  // Auth gets configured dynamically in AuthWrapper
})
Amplify.Logger.LOG_LEVEL = 'ERROR'

const CustomerRedirect = () => <Redirect to={routes.customer.INDEX} />

const onMount = () => {
  if (import.meta.env.PRO) {
    initSentry(config.sentry) // error monitoring
    console.debug('Sentry: loaded')
  } else {
    console.debug('Sentry: disabled')
  }
}

const App = observer(() => {
  useEffect(onMount, [])
  return (
    <BrowserRouter>
      <AmplifyAuthProvider>
        <RouterAuthWrapper config={config.OAuthConfig}>
          <SWRConfig
            value={{
              // refreshInterval: 3000,
              fetcher,
            }}
          >
            <AuthHelper />

            <Switch>
              <Route path={routes.customer.INDEX} component={PageCustomers} />
              <Route
                path={routes.components.INDEX}
                component={PageComponents}
              />
              <Route path={routes.provision.INDEX} component={PageProvision} />
              <Route path={routes.showcase} component={PageShowcase} />
              <Route exact path="/*" component={CustomerRedirect} />
            </Switch>
          </SWRConfig>
        </RouterAuthWrapper>
      </AmplifyAuthProvider>
    </BrowserRouter>
  )
})

const AppWithTools = observer(() => (
  <Fragment>
    <App />
  </Fragment>
))

export default AppWithTools
