import Navigation from '~/widgets/Navigation'
import { PropsWithChildren, Fragment } from 'react'

type Props = PropsWithChildren<{
  title: string
  actions?: React.ReactNode
}>

export default function Page(props: Props) {
  return (
    <Fragment>
      <Navigation pageTitle={props.title} pageActions={props.actions} />
      <main className="t-page flex justify-center">
        <div style={{ width: 'var(--app-width)' }} className="pt-4 pb-12">
          {props.children}
        </div>
      </main>
    </Fragment>
  )
}
