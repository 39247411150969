import { useEffect } from 'react'
import { BusyFullWidth } from '~/common/ui/Loading'

interface Props {
  signOut: Function
  redirect: Function
}

// If you're reading this, I'm sorry
const signOutAndSuppressAmplifyErrors = async (signOut: Function) => {
  // TODO: come back and remove this trycatch when Amplify fixes this error
  try {
    await signOut()
  } catch (error) {
    // ie: "TypeError: Cannot add property user, object is not extensible"
    console.debug(
      "Caught Amplify's error, ignoring because the user has been logged out successfully: ",
      error,
    )
  }
}

const signOutThenRedirect = async ({ signOut, redirect }: Props) => {
  await signOutAndSuppressAmplifyErrors(signOut)
  redirect()
}

const PageSignOut = (props: Props) => {
  useEffect(() => {
    signOutThenRedirect(props)
  }, [])

  return <BusyFullWidth />
}

export default PageSignOut
