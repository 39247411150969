import React from 'react'
import { observer } from 'mobx-react-lite'
import { ButtonOutline } from '~/common/ui/Buttons'
import RootStore from '~/stores/root/RootStore'
import { CanAny, Role } from '~/common/ui/Can'
import { Switch } from '~/common/ui/Switch'
import { FormTextInput } from '~/common/ui/Forms'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from '~/common/ui/Modals'

interface State {
  comment: string | null
  enabled: boolean
  open: boolean
}

type OrgId = string

type Props = {
  className?: string
  enabled: boolean
  orgId: OrgId
}

const LocalUserInviteSwitch = observer(({ enabled, ...restProps }: Props) => {
  const { organisation } = React.useContext(RootStore)
  const [state, setState] = React.useState<State>({
    comment: '',
    enabled: enabled,
    open: false,
  })

  const handleChange = () => {
    setState({ ...state, open: true })
  }

  const toggleLock = () => {
    if (state.comment) {
      // console.log(organisation, restProps)
      organisation.setConfig(
        restProps.orgId,
        'EnableLocalUserInvite',
        !state.enabled,
        state.comment,
      )
      setState({
        ...state,
        enabled: !state.enabled,
        open: false,
        comment: state.comment,
      })
    } else {
      setState({ ...state, open: false })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const updateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, comment: event.target.value })
  }

  return (
    <CanAny
      requiredRoles={[Role.customersupport, Role.deployer]}
      yes={
        <React.Fragment>
          <Switch checked={state.enabled} onChange={handleChange} />
          <Modal
            isOpen={state.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <ModalTitle id="form-dialog-title">
              Enable Local User Invites
            </ModalTitle>
            {!state.enabled ? (
              <ModalContent>
                <p>To enable local user invites a comment is required.</p>
                <FormTextInput
                  required
                  value={state.comment ? state.comment : ''}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                />
              </ModalContent>
            ) : (
              <ModalContent>
                <p>To disable local user invites a comment is required.</p>
                <FormTextInput
                  required
                  value={state.comment ? state.comment : ''}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                />
              </ModalContent>
            )}
            <ModalFooter>
              <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>
              {state.enabled ? (
                <ButtonOutline onClick={toggleLock}>Disable</ButtonOutline>
              ) : (
                <ButtonOutline onClick={toggleLock}>Enable</ButtonOutline>
              )}
            </ModalFooter>
          </Modal>
        </React.Fragment>
      }
      no={
        <div
          title={`You don't have permission to ${
            state.enabled ? 'disable' : 'enable'
          } local user invites.`}
        >
          <Switch checked={state.enabled} disabled={true} />
        </div>
      }
    />
  )
})

export default LocalUserInviteSwitch
