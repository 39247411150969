import { observer } from 'mobx-react-lite'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'
import { isDev } from '~/common/utils/stages'
import ComponentTime from './ComponentTime'
import ComponentBuild from './ComponentBuild'
import ComponentDeploySwitch from './ComponentDeploySwitch'
import { Component } from '~/stores/components/types'
import ComponentLockSwitch from './ComponentLockSwitch'
import { Status } from '~/common/ui/Status'
import { Loading } from '~/common/ui/Loading'

type Props = {
  component?: Component
  isLoading: boolean
  failing: Component[]
}

const ComponentItemContent = observer(
  ({ component, failing, isLoading, ...restProps }: Props) => {
    if (!component) return null

    const availableDeployments = component.availableDeployments || []

    const deployments = [component.activeDeployment, ...availableDeployments]

    const blocking = failing.filter(
      (item) => item.deploymentOrder < component.deploymentOrder,
    )

    if (isLoading) return <Loading />

    return (
      <div className="t-component-item-content py-4">
        <div className="t-item-header border-b border-slate-300 pb-4 px-4">
          {component && (
            <div className="flex font-semibold">
              <div>{component.name}</div>
              <div>
                {!component.deployPassing && <Status status={'FAILING'} />}
              </div>
            </div>
          )}
          <div>
            {component.activeDeployment ? (
              <ComponentTime time={component.activeDeployment.modifiedTS} />
            ) : (
              <span>No active deployment</span>
            )}
            <div>
              {blocking && blocking.length > 0
                ? `Blocked by ${blocking[0].name}`
                : undefined}
            </div>
          </div>

          <div className="t-actions py-2 space-x-4">
            <ComponentDeploySwitch
              enabled={component.autoDeploy}
              component={component}
              disabled={!isDev()}
            />
            <ComponentLockSwitch
              enabled={component.locked}
              component={component}
            />
            <div className="flex">
              {component.comments && component.comments.length > 0
                ? `${
                    component.comments.reduce((prev, current) => {
                      if (prev) {
                        return isAfter(
                          parseISO(prev.createdTS),
                          parseISO(current.createdTS),
                        )
                          ? prev
                          : current
                      }
                      return current
                    }).comment
                  }`
                : undefined}
            </div>
          </div>
        </div>

        <ul className="t-list">
          {component &&
            deployments.map((deployment) => {
              if (!deployment) return null

              const isNew =
                component.activeDeployment &&
                isAfter(
                  parseISO(deployment.createdTS),
                  parseISO(component.activeDeployment.createdTS),
                )

              const isCurrent =
                component.activeDeployment &&
                component.activeDeployment.id === deployment.id

              return (
                <li key={deployment.id}>
                  <ComponentBuild
                    activeVersion={
                      component.activeDeployment &&
                      component.activeDeployment.version
                    }
                    deployment={deployment}
                    isCurrent={isCurrent || false}
                    isNew={isNew || false}
                    locked={component.locked}
                  />
                </li>
              )
            })}
        </ul>
      </div>
    )
  },
)

export default ComponentItemContent
