import React from 'react'
import { observer } from 'mobx-react-lite'
import RootStore from '~/stores/root/RootStore'
import { useField, Formik, FormikProps, Form } from 'formik'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ButtonOutline } from '~/common/ui/Buttons'
import {
  FormField,
  FormSectionTitle,
  FormSelect,
  FormTextInput,
  RadioGroup,
  RadioWithLabel,
} from '~/common/ui/Forms'
import {
  FIELDS,
  CUSTOMER_TYPES,
  AWS_SUPPORT_TYPES,
  FIELD_HELPER_TEXTS,
  CUSTOMER_FORM_INITIAL_STATE,
} from '~/common/constantsProvision'
import {
  computeFieldStyleProps,
  renderSelectHelperTextV2,
  validateRequired,
  parseAndSetField,
} from '~/common/utils/provision'
import { CUSTOMER_SUPPORT_PLANS } from '~/common/constants'

type Props = FormikProps<typeof CUSTOMER_FORM_INITIAL_STATE>

const AddCustomerForm: React.FC<Props> = observer(
  ({ handleSubmit, setFieldValue }) => {
    const { billingroot } = React.useContext(RootStore)

    const [
      customerTypeField,
      { error: customerTypeError, touched: customerTypeTouched },
    ] = useField({ name: FIELDS.CUSTOMER_TYPE, validate: validateRequired })

    const customerTypeDirect = customerTypeField.value === CUSTOMER_TYPES.DIRECT

    const [
      customerSupportPlanField,
      { error: customerSupportPlanError, touched: customerSupportPlanTouched },
    ] = useField({
      name: FIELDS.CUSTOMER_SUPPORT_PLAN,
      validate: validateRequired,
    })

    const [
      awsSupportTypeField,
      // { error: awsSupportTypeError, touched: awsSupportTypeTouched },
    ] = useField({ name: FIELDS.AWS_SUPPORT_TYPE, validate: validateRequired })

    const [internalCustomerField] = useField({
      name: FIELDS.INTERNAL_CUSTOMER,
      validate: validateRequired,
    })

    const [partnerSupportField] = useField({
      name: FIELDS.PARTNER_SUPPORT,
      validate: !customerTypeDirect ? validateRequired : undefined,
    })

    const [marketplaceSignupField] = useField({
      name: FIELDS.MARKETPLACE_SIGNUP,
      validate: validateRequired,
    })

    const [
      billingRootField,
      { touched: billingRootTouched, error: billingRootError },
    ] = useField({ name: FIELDS.BILLING_ROOT, validate: validateRequired })

    const [
      companyNameField,
      { touched: companyNameTouched, error: companyNameError },
    ] = useField({ name: FIELDS.COMPANY_NAME, validate: validateRequired })

    const [
      staxAliasField,
      { touched: staxAliasTouched, error: staxAliasError },
    ] = useField({ name: FIELDS.STAX_ALIAS, validate: validateRequired })

    const [
      emailTemplateField,
      { touched: emailTemplateTouched, error: emailTemplateError },
    ] = useField({ name: FIELDS.EMAIL_TEMPLATE, validate: validateRequired })

    const [
      rootEmailField,
      { touched: rootEmailTouched, error: rootEmailError },
    ] = useField({ name: FIELDS.ROOT_EMAIL, validate: validateRequired })

    const [spotlightIdField] = useField({ name: FIELDS.SPOTLIGHT_ID })

    const [salesforceIdField] = useField({ name: FIELDS.SALESFORCE_ID })

    const [
      idamUserEmailField,
      { touched: idamUserEmailTouched, error: idamUserEmailError },
    ] = useField({ name: FIELDS.IDAM_USER_EMAIL, validate: validateRequired })

    const [
      idamUserFirstNameField,
      { touched: idamUserFirstNameTouched, error: idamUserFirstNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_FIRST_NAME,
      validate: validateRequired,
    })

    const [
      idamUserLastNameField,
      { touched: idamUserLastNameTouched, error: idamUserLastNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_LAST_NAME,
      validate: validateRequired,
    })

    React.useEffect(() => {
      billingroot.fetch('UNUSED')
    }, [])

    const updateRootEmailField =
      (onChange: FormikProps<any>['handleChange']) =>
      (event: React.ChangeEvent<any>) => {
        const parsedEvent = {
          ...event,
          target: {
            ...event.target,
            value: event.target.value,
            // Shouldn't HAVE to pass this, because we've spread event.target, wtf?
            name: event.target.name,
          },
        }

        // Ensure that the root email matches the billing root
        setFieldValue(rootEmailField.name, event.target.selectedOptions[0].text)

        onChange(parsedEvent)
      }

    return (
      <Form onSubmit={handleSubmit}>
        <FormSelect
          label="Customer Type"
          name={customerTypeField.name}
          onBlur={customerTypeField.onBlur}
          onChange={customerTypeField.onChange}
          required={true}
          value={customerTypeField.value}
          {...renderSelectHelperTextV2(customerTypeError, customerTypeTouched)}
        >
          {Object.values(CUSTOMER_TYPES).map((custType, index) => (
            <option value={custType} key={index}>
              {custType}
            </option>
          ))}
        </FormSelect>

        {customerTypeDirect === false ? (
          <FormField
            id={partnerSupportField.name}
            label="Partner Support?"
            required={false}
          >
            <RadioGroup>
              <RadioWithLabel
                name={partnerSupportField.name}
                value={true}
                label="Yes"
                checked={partnerSupportField.value === true}
                onChange={parseAndSetField(partnerSupportField.onChange)}
              />

              <RadioWithLabel
                checked={partnerSupportField.value === false}
                label="No"
                name={partnerSupportField.name}
                onChange={parseAndSetField(partnerSupportField.onChange)}
                value={false}
              />
            </RadioGroup>
          </FormField>
        ) : null}

        <FormField
          id={internalCustomerField.name}
          label="Internal Customer?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabel
              checked={internalCustomerField.value === true}
              label="Yes"
              name={internalCustomerField.name}
              onChange={parseAndSetField(internalCustomerField.onChange)}
              value={true}
            />

            <RadioWithLabel
              checked={internalCustomerField.value === false}
              label="No"
              name={internalCustomerField.name}
              onChange={parseAndSetField(internalCustomerField.onChange)}
              value={false}
            />
          </RadioGroup>
        </FormField>

        <FormField
          id={marketplaceSignupField.name}
          label="AWS Marketplace Signup?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabel
              checked={marketplaceSignupField.value === true}
              label="Yes"
              name={marketplaceSignupField.name}
              onChange={parseAndSetField(marketplaceSignupField.onChange)}
              value={true}
            />

            <RadioWithLabel
              checked={marketplaceSignupField.value === false}
              label="No"
              name={marketplaceSignupField.name}
              onChange={parseAndSetField(marketplaceSignupField.onChange)}
              value={false}
            />
          </RadioGroup>
        </FormField>

        <FormSelect
          label="AWS Support Type"
          name={awsSupportTypeField.name}
          onBlur={awsSupportTypeField.onBlur}
          onChange={awsSupportTypeField.onChange}
          required={true}
          value={awsSupportTypeField.value}
          {...renderSelectHelperTextV2(
            customerSupportPlanError,
            customerSupportPlanTouched,
          )}
        >
          {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType, index) => (
            <option value={awsSupportType} key={index}>
              {awsSupportType}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          label="Stax Support Plan"
          name={customerSupportPlanField.name}
          onBlur={customerSupportPlanField.onBlur}
          onChange={customerSupportPlanField.onChange}
          required={true}
          value={customerSupportPlanField.value}
          {...renderSelectHelperTextV2(
            customerSupportPlanError,
            customerSupportPlanTouched,
          )}
        >
          {Object.values(CUSTOMER_SUPPORT_PLANS).map(
            (custSupportPlan, index) => (
              <option value={custSupportPlan} key={index}>
                {custSupportPlan}
              </option>
            ),
          )}
        </FormSelect>

        <FormSelect
          label="Billing Root"
          name={billingRootField.name}
          onBlur={billingRootField.onBlur}
          onChange={updateRootEmailField(billingRootField.onChange)}
          required={true}
          value={billingRootField.value}
          {...renderSelectHelperTextV2(billingRootError, billingRootTouched)}
        >
          <option disabled value="">
            Select a billing root
          </option>
          {billingroot.poolList().map((root) => (
            <option value={root.awsAccountId} key={root.awsAccountId}>
              {root.email}
            </option>
          ))}
        </FormSelect>

        <FormTextInput
          id={companyNameField.name}
          label="Company Name"
          required={true}
          {...companyNameField}
          {...computeFieldStyleProps(
            companyNameError,
            companyNameTouched,
            FIELD_HELPER_TEXTS[FIELDS.COMPANY_NAME],
          )}
        />

        <FormTextInput
          id={staxAliasField.name}
          label="Stax Alias"
          required={true}
          {...staxAliasField}
          {...computeFieldStyleProps(
            staxAliasError,
            staxAliasTouched,
            FIELD_HELPER_TEXTS[FIELDS.STAX_ALIAS],
          )}
        />

        <FormTextInput
          id={emailTemplateField.name}
          label="Email domain template"
          required={true}
          {...emailTemplateField}
          {...computeFieldStyleProps(
            emailTemplateError,
            emailTemplateTouched,
            FIELD_HELPER_TEXTS[FIELDS.EMAIL_TEMPLATE],
          )}
        />

        <FormTextInput
          id={rootEmailField.name}
          label="Root Email"
          required={true}
          {...rootEmailField}
          {...computeFieldStyleProps(
            rootEmailError,
            rootEmailTouched,
            FIELD_HELPER_TEXTS[FIELDS.ROOT_EMAIL],
          )}
        />

        <FormTextInput
          hasError={false}
          helperText={FIELD_HELPER_TEXTS[FIELDS.SPOTLIGHT_ID]}
          id={spotlightIdField.name}
          label="Spotlight External ID"
          required={false}
          {...spotlightIdField}
        />

        <FormTextInput
          hasError={false}
          helperText={FIELD_HELPER_TEXTS[FIELDS.SALESFORCE_ID]}
          id={salesforceIdField.name}
          label="Salesforce ID"
          required={false}
          {...salesforceIdField}
        />

        <FormSectionTitle>First IDAM User</FormSectionTitle>

        <FormTextInput
          id={idamUserEmailField.name}
          label="Email"
          required={true}
          {...idamUserEmailField}
          {...computeFieldStyleProps(
            idamUserEmailError,
            idamUserEmailTouched,
            FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_EMAIL],
          )}
        />

        <FormTextInput
          id={idamUserFirstNameField.name}
          label="First Name"
          required={true}
          {...idamUserFirstNameField}
          {...computeFieldStyleProps(
            idamUserFirstNameError,
            idamUserFirstNameTouched,
            FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_FIRST_NAME],
          )}
        />

        <FormTextInput
          id={idamUserLastNameField.name}
          label="Last Name"
          required={true}
          {...idamUserLastNameField}
          {...computeFieldStyleProps(
            idamUserLastNameError,
            idamUserLastNameTouched,
            FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_LAST_NAME],
          )}
        />

        <ButtonOutline type="submit">Create Customer</ButtonOutline>
      </Form>
    )
  },
)

const ProvisionAddCustomer: React.FC<RouteComponentProps> = ({ history }) => {
  const { provision } = React.useContext(RootStore)

  const handleSubmit = async (values: typeof CUSTOMER_FORM_INITIAL_STATE) => {
    const payload = {
      OwnerName: values.companyName,
      Email: values.rootEmail,
      OrgAlias: values.staxAlias,
      CompanyName: values.companyName,
      Type: values.customerType,
      StaxSupportPlan: values.customerSupportPlan,
      AwsSupportType: values.awsSupportType,
      BillingAccount: values.billingRoot,
      MarketplaceSignup: values.marketplaceSignup,
      InternalCustomer: values.internalCustomer,
      IdamUserEmail: values.idamUserEmail,
      IdamUserFirstName: values.idamUserFirstName,
      IdamUserLastName: values.idamUserLastName,
    }

    // Optional payload keys
    if (values.spotlightId)
      Object.assign(payload, { SpotlightId: values.spotlightId })
    if (values.emailTemplate)
      Object.assign(payload, { EmailTemplate: values.emailTemplate })
    if (values.salesforceId)
      Object.assign(payload, { SalesforceId: values.salesforceId })

    if (values.partnerSupport) {
      Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
    } else {
      Object.assign(payload, { AwsPartnerSupport: false })
    }

    const res = await provision.add_customer(payload)

    if (res.status === 200) {
      window.alert('Success! Factory execution started')
      history.push('/provision')
    } else {
      window.alert('An unknown error occurred')
    }
  }

  return (
    <Formik
      initialValues={CUSTOMER_FORM_INITIAL_STATE}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => <AddCustomerForm {...formikProps} />}
    </Formik>
  )
}

export default withRouter(ProvisionAddCustomer)
