import { v4 } from 'uuid'

import { setAuthState } from './localStorage'

export interface OAuthURLOptions {
  domain?: string
  redirectSignIn?: string
  responseType?: string
}

export enum Provider {
  OKTA,
}

export const buildOAuthUrl = (
  options: OAuthURLOptions,
  clientId: string,
  provider: Provider,
) => {
  // What is this? https://auth0.com/docs/protocols/oauth2/oauth-state
  const state = v4()

  setAuthState(state)

  console.debug('OAuth login', { options, state })

  return (
    `https://${options.domain}/oauth2/authorize` +
    `?redirect_uri=${options.redirectSignIn}` +
    `&response_type=${options.responseType}` +
    `&client_id=${clientId}` +
    `&identity_provider=staxoktaprod` +
    `& state= ${state} `
  )
}
