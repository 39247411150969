import { routes } from '../../routes'

export type NavLinkName = 'customers' | 'components' | 'provision'

export type TNavLinks = Array<{
  to: string
  title: string
  name: NavLinkName
}>

export const NavLinks: TNavLinks = [
  {
    to: routes.customer.INDEX,
    name: 'customers',
    title: 'Customers',
  },
  {
    to: routes.components.INDEX,
    name: 'components',
    title: 'Components',
  },
  {
    to: routes.provision.INDEX,
    name: 'provision',
    title: 'Provision',
  },
]
