import { observable, action, makeObservable } from 'mobx'

// FIXME. Refactor this store. Maybe use context?

export default class NavigationStore {
  sectionRef: HTMLDivElement | undefined

  constructor() {
    makeObservable(this, {
      sectionRef: observable,
      setPageSection: action,
    })
  }

  setPageSection(node: HTMLDivElement) {
    this.sectionRef = node
  }
}
