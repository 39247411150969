import React from 'react'
import { observer } from 'mobx-react-lite'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import { Workload } from '~/stores/workloads/types'
import { Status } from '~/common/ui/Status'
import { Loading } from '~/common/ui/Loading'
import { ClickToCopy } from '~/common/ui/ClickToCopy'

const headers = [
  { title: 'Name' },
  { title: 'Modified' },
  { title: 'Status' },
  { title: 'ID' },
  { title: 'AccountId' },
  { title: 'Region' },
]

const getTableRows = (workloads: Workload[]) => {
  return workloads.map((item) => ({
    key: item.id,
    cells: [
      item.name,
      item.modifiedTS,
      <Status key="status" status={item.status} />,
      <React.Fragment key={item.id}>
        {item.id} <ClickToCopy text={item.id} />
      </React.Fragment>,
      item.accountId,
      item.region,
    ],
  }))
}

const CustomerWorkloads = observer(() => {
  const { workload, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    if (organisation.current) {
      workload.fetch(organisation.current.id)
    }
  }, [organisation.current])

  if (!organisation.current) {
    return <NoResults title="No organisation selected" />
  }

  const workloads = workload.list(organisation.current.id)
  const rows = getTableRows(workloads)

  const loading = organisation.isLoading || workload.isLoading

  if (loading) return <Loading />

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.cells.map((cell, i) => (
              <td key={i}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
})

export default CustomerWorkloads
