import { API } from '@aws-amplify/api'
import { API_NAME } from './constants'

// NOTE: `import { Method } from 'axios'` is more comprehensive
type Method = 'get' | 'put' | 'post' | 'delete'

type FetcherArgs = {
  url: string
  method?: Method
  body?: any
}

export const fetcher = async (args: FetcherArgs) => {
  const { url, method = 'get', body } = args

  switch (method) {
    case 'post':
      return await API.post(API_NAME, url, { body, response: true })
        .then((response) => response)
        .catch((error) => error.response.data)
    case 'put':
      return await API.put(API_NAME, url, { body, response: true })
        .then((response) => response)
        .catch((error) => error.response.data)
    case 'delete':
      return await API.del(API_NAME, url, { body, response: true })
        .then((response) => response)
        .catch((error) => error.response.data)

    default:
      return await API.get(API_NAME, url, { data: body, response: true })
        .then((response) => {
          // console.log({ response })
          return response.data
        })
        .catch((error) => error.response.data)
  }
}
