import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { compareDesc } from 'date-fns/compareDesc'
import { parseISO } from 'date-fns/parseISO'

import { RootStore } from '~/stores/root/RootStore'
import { Status } from '~/common/api/apiLegacy'
import { Component, ComponentName, Deployment } from '~/stores/components/types'
import ComponentApi from '~/stores/components/ComponentApi'

export default class ComponentStore {
  root: RootStore

  api: ComponentApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: ComponentApi, endpoint = '/component') {
    makeObservable(this, {
      status: observable,
      components: observable,
      fetch: action,
      autodeploy: action,
      lock: action,
      isLoading: computed,
      list: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  status: Status = Status.Idle

  components: ObservableMap<ComponentName, Component> = observable.map()

  fetch = async (
    force = false,
    componentId: String = '',
    nameFilter: String[] = [],
  ) => {
    let brief = '?brief=true'
    let filter = ''
    try {
      if (componentId !== '' && !componentId.startsWith('/')) {
        componentId = `/${componentId}`
        brief = '?brief=false'
      }
      if (nameFilter.length > 0) {
        filter = nameFilter.toString().endsWith(',')
          ? `?name_filter=${nameFilter.toString()}`
          : `?name_filter=${nameFilter.toString()},`
      }
      this.status = Status.Pending
      const res = await this.api.fetch(
        `${this.endpoint}${componentId}${filter}${brief}`,
        force,
      )
      for (const component of res.data.components) {
        if (component.availableDeployments) {
          component.availableDeployments = component.availableDeployments.sort(
            (left: Deployment, right: Deployment) =>
              compareDesc(parseISO(left.createdTS), parseISO(right.createdTS)),
          )
        }
        this.components.set(component.name, component)
      }
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  deploy = (id: string) => {
    return this.api.deploy(`${this.endpoint}/deployment`, id)
  }

  autodeploy = async (component: Component, value: boolean) => {
    try {
      this.status = Status.Pending
      this.api.autodeploy(`${this.endpoint}/autodeploy`, component, value)
      this.status = Status.Success
      component.autoDeploy = value
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  lock = async (component: Component, lock: boolean, comment: string) => {
    try {
      this.status = Status.Pending
      this.api.lock(`${this.endpoint}/lock`, component, lock, comment)
      this.status = Status.Success
      component.locked = lock
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  get list() {
    return Array.from(this.components.values())
  }
}
