const WITH_ORG_ID = '?orgId=:orgId'

const authRoutes = {
  LOGIN: '/login',
  PROCESS_SIGN_IN: '/auth/redirect',
  LOADING: '/loading',
  SIGN_OUT: '/signout',
}

const componentsRoot = '/components'

const componentRoutes = {
  INDEX: componentsRoot,
  DEPLOY: `${componentsRoot}/deploy`,
}

const customersRoot = '/customers'

const customerRoutes = {
  INDEX: customersRoot,
  DETAIL: `${customersRoot}/:id`,
  ORGDETAIL: `${customersRoot}/:id/OrganisationId/:orgId`,
  SERVICES: `${customersRoot}/:id/services`,
  SERVICES_CONFIGURE_SECURITY_HUB: `${customersRoot}/:id/services/configure-security-hub`,
  SERVICES_CONFIGURE_REGIONS: `${customersRoot}/:id/services/configure-regions`,
}

const provisionRoot = '/provision'

const provisionRoutes = {
  INDEX: provisionRoot,
  ADDROOT: `${provisionRoot}/AddBillingRoot`,
  ADDCUST: `${provisionRoot}/AddCustomer`,
  ADDORG: `${provisionRoot}/AddOrganisation`,
  VERIFY: `${provisionRoot}/Verify`,
}

export const routes = {
  auth: authRoutes,
  components: componentRoutes,
  customer: customerRoutes,
  provision: provisionRoutes,
  showcase: '/dev/showcase',
}

export const customerDetailLink = (customerId: string, tab = '') =>
  customerRoutes.DETAIL.replace(':id', customerId).concat(`/${tab}`)

export const configureSecurityHubLink = (customerId: string, orgId: string) =>
  customerRoutes.SERVICES_CONFIGURE_SECURITY_HUB.replace(':id', customerId)
    .concat(WITH_ORG_ID)
    .replace(':orgId', orgId)

export const configureRegionsLink = (customerId: string, orgId: string) =>
  customerRoutes.SERVICES_CONFIGURE_REGIONS.replace(':id', customerId)
    .concat(WITH_ORG_ID)
    .replace(':orgId', orgId)
