import React from 'react'

type Status = 'PENDING' | 'SUCCESS' | 'FAILED' | 'IDLE'

type Props = {
  request: Promise<any> | undefined
  delay?: number
  onSuccess?: (delay?: number) => void
  onFailure?: (delay?: number) => void
  children: (injectedProps: any) => JSX.Element
}

const AsyncRequest = ({
  request,
  delay = 3000,
  children,
  onSuccess,
  onFailure,
  ...restProps
}: Props) => {
  const [status, setStatus] = React.useState<Status>('IDLE')
  React.useEffect(() => {
    if (request) {
      setStatus('PENDING')
      request
        .then(() => {
          setStatus('SUCCESS')
          if (onSuccess) {
            onSuccess(delay)
          }
        })
        .catch(() => {
          setStatus('FAILED')
          if (onFailure) {
            onFailure(delay)
          }
        })
        .finally(() => {
          setTimeout(() => {
            setStatus('IDLE')
          }, delay)
        })
    }
  }, [request])

  return children({
    status,
  })
}

export default AsyncRequest
