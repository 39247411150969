import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  match as RouterMatch,
  withRouter,
  RouteComponentProps,
  Route,
} from 'react-router'
import { Panel } from '~/common/ui/Panels'
import CustomerAndOrgDetails from '~/widgets/CustomerAndOrgDetails'
import CustomerAccountList from '~/widgets/CustomerAccountList'
import CustomerWorkloads from '~/widgets/CustomerWorkloads'
import CustomerCatalogue from '~/widgets/CustomerCatalogue'
import CustomerUserList from '~/widgets/CustomerUserList'
import RootStore from '~/stores/root/RootStore'
import CustomerServiceList from '~/widgets/CustomerServiceList'
import CustomerPolicyList from '~/widgets/CustomerPolicyList'
import CustomerOrganisationalUnitList from '~/widgets/CustomerOrganisationalUnitList'
import Page from '~/common/ui/Page'
import classNames from 'classnames'

type Props = RouteComponentProps & {
  match: RouterMatch<{ id: string; orgId?: string }>
}

const tabs = [
  {
    label: 'Accounts',
    // Open this tab by default
    subroute: '',
    Component: CustomerAccountList,
    exact: true,
  },
  {
    label: 'Workloads',
    subroute: '/workloads',
    Component: CustomerWorkloads,
    exact: true,
  },
  {
    label: 'Catalogue',
    subroute: '/catalogue',
    Component: CustomerCatalogue,
    exact: true,
  },
  {
    label: 'Users',
    subroute: '/users',
    Component: CustomerUserList,
    exact: true,
  },
  {
    label: 'Services',
    subroute: '/services',
    Component: CustomerServiceList,
    exact: false, // allow for disable modal
  },
  {
    label: 'Policies',
    subroute: '/policies',
    Component: CustomerPolicyList,
    exact: false, // allow for disable modal
  },
  {
    label: 'Organisational Units',
    subroute: '/organisational-units',
    Component: CustomerOrganisationalUnitList,
    exact: false, // allow for disable modal
  },
]

const PageCustomerShow = observer((props: Props) => {
  const { history, match, location } = props
  const { customer, billingroot, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    customer.setActive(match.params['id'])
    billingroot.fetch('UNUSED')
  }, [])

  const changeTab = (subroute: string) => {
    history.push(`${match.url}${subroute}`)
  }

  return (
    <Page title={(customer.active && customer.active.name) ?? ''}>
      <CustomerAndOrgDetails
        customerId={match.params.id}
        organisationId={match.params.orgId}
      />

      {!organisation.current?.id && 'Loading...'}

      {!!organisation.current?.id && (
        <Panel>
          <div className="flex space-x-2 border-b border-slate-300">
            {tabs.map((tab) => {
              const isActive =
                location.pathname === `${match.url}${tab.subroute}`

              const classes = classNames({
                'p-3 cursor-pointer border-b-4': true,
                'border-b-white': !isActive,
                'border-b-sky-300': isActive,
              })

              const onClick = () => changeTab(tab.subroute)

              return (
                <div className={classes} key={tab.label} onClick={onClick}>
                  {tab.label}
                </div>
              )
            })}
          </div>

          {tabs.map(({ subroute, Component, exact = true }, index) => (
            <Route
              exact={exact}
              path={`${match.path}${subroute}`}
              component={Component}
              key={index}
            />
          ))}
        </Panel>
      )}
    </Page>
  )
})

export default withRouter(PageCustomerShow)
