import React from 'react'
import { observer } from 'mobx-react-lite'
import Toast from '~/common/ui/Toast'
import RootStore from '~/stores/root/RootStore'
import { ButtonSolid } from '~/common/ui/Buttons'

const redirect = async () => {
  // TODO: there must be a better way
  window.location.reload()
}

const Message = <span id="message-id">Your session expired</span>

const Action = <ButtonSolid onClick={redirect}>Login</ButtonSolid>

/**
 * Handles authentication things - currently only expired session
 */
const AuthHelper = observer(() => {
  const { auth } = React.useContext(RootStore)
  return (
    <Toast
      open={auth.hasError}
      variant="error"
      message={Message}
      action={Action}
      aria-describedby="message-id"
    />
  )
})

export default AuthHelper
