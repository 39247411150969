import { withAmplifyAuth } from '~/wrappers/AmplifyListener'
import { State } from '~/wrappers/AmplifyListener.d'

type Props = {
  requiredRole?: Role
  yes?: React.ReactElement | null
  no?: React.ReactElement | null
  authState?: State
}

export enum Role {
  readonly = 'app-jumaadmin-readonly',
  customersuccess = 'app-jumaadmin-customersuccess',
  finance = 'app-jumaadmin-finance',
  customersupport = 'app-jumaadmin-customersupport',
  deployer = 'app-jumaadmin-deployer',
  admin = 'app-jumaadmin-admin',
}

const RoleHierarchy = {
  'app-jumaadmin-readonly': 0,
  'app-jumaadmin-customersuccess': 1,
  'app-jumaadmin-finance': 2,
  'app-jumaadmin-customersupport': 3,
  'app-jumaadmin-deployer': 4,
  'app-jumaadmin-admin': 5,
}

export const check = (idamRoles: String[] = [], requiredRole: Role) => {
  const requiredRoleLevel = RoleHierarchy[requiredRole]

  for (const grant of idamRoles) {
    const isAdmin = grant === Role.admin

    const roleLevel: number = RoleHierarchy[grant as Role] || 0

    if (isAdmin || roleLevel >= requiredRoleLevel) {
      return true
    }
  }

  return false
}

const Can = ({ requiredRole, yes = null, no = null, authState }: Props) => {
  const idamRoles =
    (authState && authState.user && authState.user.idamRoles) ||
    new Array<String>()

  if (requiredRole && idamRoles) {
    return check(idamRoles, requiredRole) ? yes : no
  }

  return no
}

export default withAmplifyAuth(Can)

type CanAnyProps = {
  requiredRoles?: [Role]
  yes?: React.ReactElement | null
  no?: React.ReactElement | null
  authState?: State
}

const CanAny_ = ({
  requiredRoles,
  yes = null,
  no = null,
  authState,
}: CanAnyProps) => {
  const idamRoles =
    (authState && authState.user && authState.user.idamRoles) ||
    new Array<string>()

  const checkRole = (role: Role) => check(idamRoles, role)

  if (requiredRoles && idamRoles) {
    return requiredRoles.some(checkRole) ? yes : no
  }

  return no
}

export const CanAny = withAmplifyAuth(CanAny_)

type IsAnyProps = {
  requiredRoles?: [Role]
  yes?: React.ReactElement | null
  no?: React.ReactElement | null
  authState?: State
}

const IsAny_ = ({
  requiredRoles,
  yes = null,
  no = null,
  authState,
}: IsAnyProps) => {
  const idamRoles =
    (authState && authState.user && authState.user.idamRoles) ||
    new Array<string>()

  const checkRole = (role: Role) => idamRoles.includes(role)

  if (requiredRoles && idamRoles) {
    return requiredRoles.some(checkRole) ? yes : no
  }

  return no
}

export const IsAny = withAmplifyAuth(IsAny_)
