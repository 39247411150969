import classNames from 'classnames'

type FormFieldProps = React.PropsWithChildren<{
  id: string
  label: string
  required: boolean
}>

/*
A wrapper for form fields.
This includes the label.
*/
export const FormField = (props: FormFieldProps) => {
  return (
    <div className="t-form-field pb-3">
      <label className="block text-slate-500 pb-2" htmlFor={props.id}>
        {props.label}
        {props.required && ' *'}
      </label>
      {props.children}
    </div>
  )
}

type FormHelpTextProps = {
  isError: boolean
  message?: string
}

/*
Form field text help or error message
*/
const FormHelpText = (props: FormHelpTextProps) => {
  if (!props.message) return null

  return (
    <div
      className={classNames({
        'font-sm': true,
        'text-slate-400': !props.isError,
        'text-red-400': props.isError,
      })}
    >
      {props.message}
    </div>
  )
}

type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
}

/*
Just a standard text input with our styles
*/
export const TextInput = (props: TextInputProps) => {
  const inputClasses = classNames(
    'block px-3 py-2 mt-1 text-sm rounded-md',
    'border border-slate-300',
    'focus:outline-none focus:ring-indigo-500 focus:border-indigo-500',
    props.className,
  )

  return <input {...props} className={inputClasses} />
}

type FormTextInputProps = {
  disabled?: boolean
  hasError?: boolean
  helperText?: string
  id: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  label: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  required?: boolean
  type?: string
  value?: string
}

/*
A text input inside a form field wrapper
*/
export const FormTextInput = (props: FormTextInputProps) => {
  return (
    <FormField
      label={props.label}
      required={props.required ?? false}
      id={props.id}
    >
      <TextInput
        className="w-full"
        disabled={props.disabled}
        id={props.id}
        onBlur={props.onBlur}
        onChange={props.onChange}
        placeholder={props.placeholder}
        type={props.type ?? 'text'}
        value={props.value}
        {...props.inputProps}
      />

      <FormHelpText
        message={props.helperText}
        isError={props.hasError ?? false}
      />
    </FormField>
  )
}

export const FormSectionTitle = (props: React.PropsWithChildren<{}>) => {
  return (
    <h2 className="text-md font-semibold text-slate-700 pb-2">
      {props.children}
    </h2>
  )
}

type InlineControlAndLabelProps = React.PropsWithChildren<{
  label: string
  className?: string
}>

/*
A control with a label on the side
*/
export const InlineControlAndLabel = (props: InlineControlAndLabelProps) => {
  const classes = classNames('inline-flex items-center', props.className)

  return (
    <label className={classes}>
      {props.children}
      <span className="pl-1">{props.label}</span>
    </label>
  )
}

type SelectProps = React.PropsWithChildren<{
  className?: string
  disabled?: boolean
  id?: string
  name: string
  onBlur?: (event: React.FocusEvent<HTMLSelectElement, Element>) => void
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: string
}>

export const Select = (props: SelectProps) => {
  const classes = classNames(
    'border border-slate-400 rounded p-2',
    props.className,
  )
  return (
    <select
      className={classes}
      disabled={props.disabled}
      id={props.id}
      name={props.name}
      onBlur={props.onBlur}
      onChange={props.onChange}
      value={props.value}
    >
      {props.children}
    </select>
  )
}

export type FormSelectProps = SelectProps & {
  hasError?: boolean
  helperText?: string
  label: string
  required?: boolean
}

export const FormSelect = (props: FormSelectProps) => {
  const id = props.id ?? `select-${props.name}`
  const required = props.required ?? false

  return (
    <FormField id={id} label={props.label} required={required}>
      <Select
        className="w-full"
        disabled={props.disabled}
        id={id}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value}
      >
        {props.children}
      </Select>

      <FormHelpText
        message={props.helperText}
        isError={props.hasError ?? false}
      />
    </FormField>
  )
}

type RadioProps = {
  name: string
  value: boolean | string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Radio = (props: RadioProps) => {
  const value =
    typeof props.value === 'string' ? props.value : props.value.toString()

  return (
    <input
      type="radio"
      name={props.name}
      value={value}
      checked={props.checked}
      onChange={props.onChange}
      style={{ width: '1.25em', height: '1.25em' }}
    />
  )
}

type RadioWithLabelProps = RadioProps & {
  label: string
}

export const RadioWithLabel = (props: RadioWithLabelProps) => {
  return (
    <label className=" text-slate-500 pb-2" htmlFor={props.name}>
      <Radio {...props} />
      <span className="ml-1">{props.label}</span>
    </label>
  )
}

export const RadioGroup = (props: React.PropsWithChildren<{}>) => {
  return <div className="flex space-x-2">{props.children}</div>
}
