import { Route, Switch } from 'react-router'
import { routes } from '~/routes'
import PageCustomerList from './PageCustomerList'
import PageCustomerShow from './PageCustomerShow'

const PageCustomers = () => (
  <Switch>
    <Route exact path={routes.customer.INDEX} component={PageCustomerList} />
    <Route path={routes.customer.ORGDETAIL} component={PageCustomerShow} />
    <Route path={routes.customer.DETAIL} component={PageCustomerShow} />
  </Switch>
)

export default PageCustomers
