import React from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import { User } from '~/stores/users/types'
import { Organisation } from '~/stores/organisations/types'
import Can, { Role } from '~/common/ui/Can'
import ComponentSendVerifyEmail from './CustomerUserList/ComponentSendVerifyEmail'
import { Loading } from '~/common/ui/Loading'
import { ClickToCopy } from '~/common/ui/ClickToCopy'

const headers = [
  { title: 'Name' },
  { title: 'Email' },
  { title: 'Federated' },
  { title: 'MFA' },
  { title: 'Enabled' },
  { title: 'Email Verified' },
]

const CustomerUserList: React.FC<RouteComponentProps> = observer(
  ({ history, match }) => {
    const { user: userStore, organisation: organisationStore } =
      React.useContext(RootStore)

    React.useEffect(() => {
      if (organisationStore.current) {
        userStore.fetch(organisationStore.current.id)
      }
    }, [organisationStore.current])

    if (!organisationStore.current) {
      return <NoResults title="No organisation selected" />
    }

    if (organisationStore.current.status === 'SUSPENDED') {
      return <NoResults title="Organisation is suspended" />
    }

    const users = userStore.list(organisationStore.current.id)
    const organisationId = (organisationStore.current as Organisation).id

    if (users.length === 0) {
      return <NoResults title="No users found" />
    }

    const getTableRows = (users: User[]) => {
      const renderButton = (user: User, disabled = false) => {
        let text = 'Re-send'
        if (user.emailVerified || !user.enabled || user.federated) {
          text = user.emailVerified ? 'Verified' : 'Not Verified'
          disabled = true
        }

        return (
          <ComponentSendVerifyEmail
            userId={user.id}
            organisationId={organisationId}
            disabled={disabled}
            text={text}
          />
        )
      }

      return users.map((user) => ({
        key: user.id,
        cells: [
          <React.Fragment key={user.id}>
            {user.firstName} {user.lastName} <br />
            {user.id} <ClickToCopy text={user.id} />
          </React.Fragment>,
          <React.Fragment key={user.email}>
            {user.email} <ClickToCopy text={user.email} />
          </React.Fragment>,
          // TODO: "non-federated" or "user"?
          user.federated ? 'Federated' : 'User',
          user.mfa ? 'Enabled' : 'Disabled',
          user.enabled ? 'Enabled' : 'Disabled',
          <Can
            key={user.id}
            requiredRole={Role.customersuccess}
            yes={renderButton(user)}
            no={renderButton(user, true)}
          />,
        ],
      }))
    }

    const rows = getTableRows(users)

    if (organisationStore.isLoading || userStore.isLoading) return <Loading />

    return (
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.key}>
              {row.cells.map((cell, index) => (
                <td key={index}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  },
)

export default CustomerUserList
