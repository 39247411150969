import { useCopyToClipboard } from '~/common/hooks'
import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/solid'

type Props = {
  text?: string
}

const style = {
  width: '16px',
  height: '16px',
}

const Copied = () => (
  <ClipboardDocumentCheckIcon className="fill-slate-300" style={style} />
)

const NotCopied = () => (
  <ClipboardDocumentIcon className="fill-slate-500" style={style} />
)

export const ClickToCopy = (props: Props) => {
  const { text = '' } = props
  const [copied, copy] = useCopyToClipboard(text)

  return (
    <button
      className="cursor-copy"
      type="button"
      onClick={copy}
      title={copied ? 'Copied!' : 'Click to copy'}
    >
      {copied && <Copied />}
      {!copied && <NotCopied />}
    </button>
  )
}
