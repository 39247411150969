import memoize from 'lodash/memoize'
import { get, post } from '~/common/api/apiLegacy'

export interface UserApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
  sendVerifyEmail: (
    endpoint: string,
    orgId: string,
    userId: string,
  ) => Promise<any>
}

class UserService implements UserApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }

  sendVerifyEmail = (endpoint: string, orgId: string, userId: string) => {
    return post(endpoint, { OrganisationId: orgId, UserId: userId })
  }
}

export default UserService
