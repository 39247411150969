import React from 'react'
import { openInNewTabConfirm, openInNewTab } from '~/common/utils/opener'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import Can, { Role } from '~/common/ui/Can'
import { isDev } from '~/common/utils/stages'
import { ButtonOutline } from '~/common/ui/Buttons'

const requiredRoleFor =
  ({
    isBillingRoot,
    organisationAlias,
  }: {
    isBillingRoot: boolean
    organisationAlias: string
  }) =>
  (memberAccountName: string, customerType: string): Role => {
    if (isBillingRoot && customerType !== 'DIRECT') {
      return Role.finance
    } else if (
      organisationAlias === 'juma' &&
      (memberAccountName === 'billing' ||
        memberAccountName.startsWith('billing-central'))
    ) {
      return Role.finance
    } else {
      return Role.customersupport
    }
  }

interface ConfirmButtonProps {
  targetURL: string
  memberAccountName: string
  customerType?: string
  organisationName?: string
  isBillingRoot?: boolean
  organisationAlias?: string
  awsSupportCaseStaxManaged?: boolean
  text?: string
  tooltip?: string
}

const ExternalLinkIcon = () => (
  <ArrowTopRightOnSquareIcon
    style={{
      marginLeft: '4px',
      fontSize: '1.15rem',
      height: '16px',
      width: '16px',
    }}
  />
)

export const LoginToAwsButton: React.FC<ConfirmButtonProps> = ({
  targetURL,
  memberAccountName,
  customerType = 'none',
  organisationName = 'none',
  isBillingRoot = false,
  organisationAlias = 'none',
  awsSupportCaseStaxManaged = false,
  text = 'Login',
  tooltip = 'Open in AWS Console',
}) => {
  // confirmation is skipped according to these conditions
  const isConfirmationSkipped = (): boolean => {
    if (isDev()) {
      return true
    } else if (organisationName === 'jumaroot') {
      return true
    } else if (customerType === 'SPAM') {
      return true
    } else if (customerType === 'ECAM' && isBillingRoot) {
      return true
    } else if (customerType === 'DIRECT') {
      return false
    } else if (awsSupportCaseStaxManaged) {
      return true
    }
    return false
  }

  const requiredRole = requiredRoleFor({ isBillingRoot, organisationAlias })

  return (
    <Can
      requiredRole={requiredRole(memberAccountName, customerType)}
      yes={
        <ButtonOutline
          onClick={() =>
            isConfirmationSkipped()
              ? openInNewTab(targetURL)
              : openInNewTabConfirm(targetURL)
          }
          title={tooltip}
          variant={isConfirmationSkipped() ? 'Primary' : 'Danger'}
        >
          {text} <ExternalLinkIcon />
        </ButtonOutline>
      }
      no={
        <DisabledLoginToAwsButton tooltip="You don't have permission to login to AWS Console" />
      }
    />
  )
}

interface RequestButtonProps {
  memberAccountName: string
  onClick: () => void
  customerType?: string
  isBillingRoot?: boolean
  organisationAlias?: string
  text?: string
  tooltip?: string
}
export const RequestLoginToAwsButton: React.FC<RequestButtonProps> = ({
  memberAccountName,
  onClick,
  customerType = 'none',
  isBillingRoot = false,
  organisationAlias = 'none',
  text = 'Request Access',
  tooltip = 'Admin access expired',
}) => {
  const requiredRole = requiredRoleFor({ isBillingRoot, organisationAlias })

  return (
    <Can
      requiredRole={requiredRole(memberAccountName, customerType)}
      yes={
        <ButtonOutline onClick={onClick} title={tooltip} variant="Secondary">
          {text}
        </ButtonOutline>
      }
      no={
        <DisabledLoginToAwsButton tooltip="You don't have permission to login to AWS Console" />
      }
    />
  )
}

interface DisabledButtonProps {
  text?: string
  tooltip: string
}
export const DisabledLoginToAwsButton: React.FC<DisabledButtonProps> = ({
  text = 'Login',
  tooltip,
}) => (
  <ButtonOutline variant="Secondary" disabled={true} title={tooltip}>
    {text} <ExternalLinkIcon />
  </ButtonOutline>
)
