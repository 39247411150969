import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Customer } from '~/stores/customers/types'
import { Loading } from '~/common/ui/Loading'
import { routes } from '../../routes'
import { Status } from '~/common/ui/Status'
import { StaxLogo } from '~/common/ui/StaxLogo'
import { Panel } from '~/common/ui/Panels'

type Props = RouteComponentProps & {
  isLoading: boolean
  headers: string[]
  customers: Customer[]
}

const renderCustomers = (props: Props, customers: Customer[]) => {
  return customers.map((customer: Customer) => {
    const link = `${routes.customer.INDEX}/${customer.id}`
    const onClick = () => props.history.push(link)

    // Make all cells clickable, except the "More actions" and "run factory"
    const ClickableCell = (props: React.PropsWithChildren<{}>) => (
      <td
        onClick={onClick}
        className="cursor-pointer group-hover:bg-slate-100"
        title={`View customer details (${customer.name})`}
      >
        {props.children}
      </td>
    )

    return (
      <tr key={customer.id} className="group">
        <ClickableCell>
          <Link to={link}>
            <span className="font-semibold">{customer.name}</span>{' '}
            {customer.internal && (
              <span title="Stax-managed Internal Customer">
                <StaxLogo className="w-4 inline-block ml-1" />
              </span>
            )}
          </Link>
        </ClickableCell>

        <ClickableCell>{customer.supportPlan}</ClickableCell>

        <ClickableCell>{customer.type}</ClickableCell>

        <ClickableCell>
          <Status status={customer.status} />
        </ClickableCell>
      </tr>
    )
  })
}

const CustomerTable = (props: Props) => {
  const { headers, customers, isLoading } = props

  if (isLoading) return <Loading />

  return (
    <Panel>
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{!isLoading && renderCustomers(props, customers)}</tbody>
      </table>
    </Panel>
  )
}

export default withRouter(CustomerTable)
