import classNames from 'classnames'
import React from 'react'

type Variant = 'Info' | 'Success' | 'Error'

type Props = React.PropsWithChildren<{
  variant?: Variant
  className?: string
}>

export const Notice: React.FC<Props> = (props) => {
  const { variant = 'Info' } = props

  return (
    <div
      className={classNames(
        't-notice',
        'p-100 border rounded',
        {
          'border-amber-500 bg-amber-100': variant === 'Info',
          'border-pink d500 bg-pink-100': variant === 'Error',
          'border-green-500 bg-green-100': variant === 'Success',
        },
        props.className,
      )}
    >
      <div className="p-2">{props.children}</div>
    </div>
  )
}
