import React from 'react'
import { observer } from 'mobx-react-lite'
import { add } from 'date-fns/add'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'
import { Number as ENumber, Option } from 'effect'
import { ButtonOutline } from '~/common/ui/Buttons'
import { AuthContext } from '~/wrappers/AmplifyListener'
import OrganisationStore from '~/stores/organisations/OrganisationStore'
import { FormTextInput, FormSelect } from '~/common/ui/Forms'
import { Account } from '~/stores/accounts/types'
import {
  LoginToAwsButton,
  RequestLoginToAwsButton,
} from '~/common/ui/ConfirmLinkButton'
import { currentStageId } from '~/common/utils/stages'
import { IsAny, Role } from '~/common/ui/Can'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from '~/common/ui/Modals'

type State = {
  duration: number
  isOpen: boolean
  reason: string
}

type Props = {
  account: Account
  organisation: OrganisationStore
}

const ADMIN_ACCESS_EXEMPT_ALIASES = ['juma', 'smoko']
const DURATIONS = [30, 60, 90, 120]

const baseState = {
  duration: DURATIONS[0],
  isOpen: false,
  reason: '',
}

const isBillingRoot = ({ billingAccountId }: { billingAccountId: string }) =>
  !billingAccountId

// If an account is either created by stax OR a billing root it is "Stax Managed"
const isStaxManaged = (account: Account): boolean => {
  return !!account.staxCreated || isBillingRoot(account)
}

const MaybeLogin = observer(({ account, organisation }: Props) => {
  const [state, setState] = React.useState<State>(baseState)
  const { authState } = React.useContext(AuthContext)

  if (!organisation.current || !authState) {
    return null
  }

  const hasRequestedAccess = organisation.accessRequestAccounts.includes(
    account.id,
  )

  const handleClose = () => setState(baseState)

  const handleChangeDuration = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = event.currentTarget.value

    const duration = Option.getOrElse(ENumber.parse(value), () => 30)

    setState({
      ...state,
      duration,
    })
  }

  const handleChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, reason: event.currentTarget.value })
  }

  const handleStartRequest = () => setState({ ...state, isOpen: true })

  const handleSubmit = () => {
    const { duration, reason } = state
    // validate
    if (!duration || !reason) return

    organisation.submitAccessRequest(organisation.current!.id, {
      accessDurationInMins: state.duration,
      reason: state.reason,
      requestor: authState.user?.id || '',
      staxAccountId: account.id,
    })
    setState(baseState)
  }

  const adminAccessExempt =
    currentStageId().includes('dev') ||
    (organisation.current?.alias &&
      ADMIN_ACCESS_EXEMPT_ALIASES.includes(organisation.current.alias)) ||
    account.accountRole === 'MANAGEMENT_RESELL'

  const orgConfig = organisation.listConfig(organisation.current.id)

  const accountAdminAccessRolesTTLInHours: number =
    orgConfig.find(
      (config) => config.key === `AdminAccessRolesTTLInHours/${account.id}`,
    )?.value || 0

  const accountAdminAccessRolesExpiry = add(parseISO(account.createdTS), {
    hours: accountAdminAccessRolesTTLInHours,
  })

  const orgAdminAccessRolesTTLInHours: number =
    orgConfig.find((config) => config.key === 'AdminAccessRolesTTLInHours')
      ?.value || 0

  const orgAdminAccessRolesExpiry = add(
    parseISO(organisation.current.createdTS),
    {
      hours: orgAdminAccessRolesTTLInHours,
    },
  )

  const accountAdminAccessExpired: boolean = isAfter(
    new Date(),
    accountAdminAccessRolesExpiry,
  )
  const orgAdminAccessExpired: boolean = isAfter(
    new Date(),
    orgAdminAccessRolesExpiry,
  )
  const adminAccessExpired: boolean =
    accountAdminAccessExpired && orgAdminAccessExpired

  console.debug(
    account.name,
    { adminAccessExpired, adminAccessExempt },
    {
      account: {
        accountAdminAccessExpired,
        accountAdminAccessRolesTTLInHours,
        accountAdminAccessRolesExpiry,
      },
      org: {
        orgAdminAccessExpired,
        orgAdminAccessRolesTTLInHours,
        orgAdminAccessRolesExpiry,
      },
    },
  )

  const awsLoginURLRegex =
    /(.+)(\/auth\/realms\/master\/protocol\/saml\/clients\/)(\d+)/

  const supportAwsLoginURL = isStaxManaged(account)
    ? account.awsLoginURL.replace(awsLoginURLRegex, '$1$2support-$3')
    : account.awsLoginURL

  const loginText = hasRequestedAccess ? 'Requested' : 'Login'

  const loginTip = hasRequestedAccess
    ? 'Open in AWS Console (access may not be provisioned for up to 5 minutes)'
    : 'Open in AWS Console'

  const LoginButton = ({ targetURL }: { targetURL: string }) => (
    <LoginToAwsButton
      customerType={organisation.current?.organisationType}
      isBillingRoot={isBillingRoot(account)}
      memberAccountName={account.name}
      organisationAlias={organisation.current?.alias}
      organisationName={organisation.current?.name}
      targetURL={targetURL}
      text={loginText}
      tooltip={loginTip}
    />
  )

  const showRequestAccess =
    adminAccessExpired && !adminAccessExempt && !hasRequestedAccess

  return (
    <>
      {showRequestAccess ? (
        <RequestLoginToAwsButton
          customerType={organisation.current?.organisationType}
          isBillingRoot={isBillingRoot(account)}
          memberAccountName={account.name}
          organisationAlias={organisation.current?.alias}
          tooltip={
            accountAdminAccessRolesTTLInHours && accountAdminAccessExpired
              ? `Admin access expired (${account.createdTS} + ${accountAdminAccessRolesTTLInHours} hours)`
              : `Admin access expired (${organisation?.current?.createdTS} + ${orgAdminAccessRolesTTLInHours} hours)`
          }
          onClick={handleStartRequest}
        />
      ) : (
        <IsAny
          requiredRoles={[Role.customersupport]}
          yes={<LoginButton targetURL={supportAwsLoginURL} />}
          no={<LoginButton targetURL={account.awsLoginURL} />}
        />
      )}
      <Modal
        isOpen={state.isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <ModalTitle id="form-dialog-title">Request Account Access</ModalTitle>
        <ModalContent>
          <div className="space-y-3 pb-4">
            <p>This account is owned by the customer, not Stax.</p>
            <p>
              You should not log into this account without permission from the
              customer.
            </p>
            <p>Logging into this account will trigger an INCIDENT.</p>
            <p>Are you sure you want to log into this customer account?</p>
            <p>
              If you are sure, select the desired access duration and provide a
              link to the support ticket below.
            </p>
            <p>
              Please wait up to 5 minutes for access to be provisioned (login
              button may be displayed before provisioning is complete)
            </p>
          </div>

          <FormTextInput
            id="input-aws-account"
            disabled
            label="AWS Account"
            value={`${account.name} - ${account.awsAccountId}`}
          />

          <FormTextInput
            id="input-requester"
            disabled
            label="Requester"
            value={`${authState.user?.name} - ${authState.user?.id}`}
          />

          <FormSelect
            id="duration-label"
            label="Duration in minutes"
            name="duration-label"
            onChange={handleChangeDuration}
            value={state.duration.toString()}
          >
            {DURATIONS.map((d) => (
              <option value={d} key={d}>
                {d}
              </option>
            ))}
          </FormSelect>

          <FormTextInput
            id="reason"
            label="Support ticket ID"
            onChange={handleChangeReason}
            placeholder="ID of support ticket or Jira card"
            required
            type="text"
            value={state.reason}
          />
        </ModalContent>

        <ModalFooter>
          <ButtonOutline onClick={handleClose} variant="Secondary">
            Cancel
          </ButtonOutline>
          <ButtonOutline onClick={handleSubmit}>Request</ButtonOutline>
        </ModalFooter>
      </Modal>
    </>
  )
})

export default MaybeLogin
