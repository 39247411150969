import React from 'react'
import ProvisionAddOrg from '~/widgets/ProvisionAddOrg'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Page from '~/common/ui/Page'
import { NarrowContainerWithPadding } from '~/common/ui/Panels'

type Props = RouteComponentProps<
  {},
  {},
  { customerName?: string; customerId?: string }
>

const PageProvisionAddOrg: React.FC<Props> = ({ location }) => {
  const { state } = location

  return (
    <Page title="Provision">
      <NarrowContainerWithPadding>
        <h2 className="text-lg pb-4">
          {`Add new organisation to
              ${
                state && state.customerName
                  ? state.customerName
                  : 'a Stax customer'
              }
            `}
        </h2>
        <ProvisionAddOrg selectedCustomerId={state && state.customerId} />
      </NarrowContainerWithPadding>
    </Page>
  )
}

export default withRouter(PageProvisionAddOrg)
