import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ProvisionVerification from '~/widgets/ProvisionVerification'
import Page from '~/common/ui/Page'
import { NarrowContainerWithPadding } from '~/common/ui/Panels'

type Props = RouteComponentProps

const PageProvisionVerification: React.FC<Props> = (props) => (
  <Page title="Verify Onboarding">
    <NarrowContainerWithPadding>
      <p>Verify the onboarding status of an existing AWS Account into Stax</p>
      <ProvisionVerification {...props} />
    </NarrowContainerWithPadding>
  </Page>
)

export default withRouter(PageProvisionVerification)
