import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

type Props = {
  className?: string
  style?: React.CSSProperties
}

export default (props: Props) => (
  <QuestionMarkCircleIcon
    className={classNames('fill-slate-300', props.className)}
    style={props.style}
  />
)
