import React from 'react'
import AsyncRequest from '~/common/ui/AsyncRequest'
import { ButtonOutline } from '~/common/ui/Buttons'

type Props = {
  children: React.ReactNode
  disabled?: boolean
  onClick?: () => Promise<unknown> | undefined
  title?: string
}

const FactoryButton = (props: Props) => {
  const [request, setRequest] = React.useState<Promise<unknown> | undefined>(
    undefined,
  )

  const handleOnClick = () => {
    const promise = props.onClick && props.onClick()
    promise && setRequest(promise)
  }

  return (
    <AsyncRequest request={request}>
      {({ status }) => (
        <ButtonOutline
          onClick={handleOnClick}
          disabled={props.disabled}
          title={props.title}
        >
          {status !== 'PENDING' && status !== 'FAILED' && props.children}
          {status === 'PENDING' && 'Loading...'}
          {status !== 'PENDING' && status === 'FAILED' && 'Error'}
        </ButtonOutline>
      )}
    </AsyncRequest>
  )
}

export default FactoryButton
