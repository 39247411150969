import ProvisionAddCustomer from '~/widgets/ProvisionAddCustomer'
import Page from '~/common/ui/Page'
import { NarrowContainerWithPadding } from '~/common/ui/Panels'

const PageProvisionAddCustomer = () => (
  <Page title="Provision">
    <NarrowContainerWithPadding>
      <h2 className="text-lg pb-4">Add New Customer to Stax installation</h2>
      <ProvisionAddCustomer />
    </NarrowContainerWithPadding>
  </Page>
)

export default PageProvisionAddCustomer
