import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { NavLinkName } from './NavLinks'
import {
  HomeIcon,
  AdjustmentsVerticalIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/solid'

const NameToIcon = {
  customers: HomeIcon,
  components: AdjustmentsVerticalIcon,
  provision: PlusCircleIcon,
}

interface NavIconProps {
  title: string
  name: NavLinkName
  compact: boolean
}

const NavIcon = (props: NavIconProps) => {
  const { compact, name } = props

  const Icon = NameToIcon[name]

  return (
    <div className="t-nav-icon flex flex-col items-center py-4">
      {!compact && (
        <div className="pb-2">
          <Icon style={{ height: '1rem' }} />
        </div>
      )}
      <div className="">{props.title}</div>
    </div>
  )
}

type Props = React.ComponentProps<typeof NavLink> & {
  className?: string
  compact: boolean
  name: NavLinkName
}

const NavItem = (props: Props) => {
  return (
    <NavLink
      to={props.to}
      className={classNames('text-white opacity-50', props.className)}
      activeStyle={{
        opacity: 1,
        borderBottom: props.compact ? '3px solid #00CCCF' : 'none',
      }}
    >
      <NavIcon
        name={props.name}
        title={props.title ?? ''}
        compact={props.compact}
      />
    </NavLink>
  )
}

export default NavItem
