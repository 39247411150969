import { format, parseISO } from 'date-fns'
import React from 'react'

export enum DateTimeFormat {
  StandardDate = 'd MMMM y',
  // the blessed data format based on consultation with BenS
  StandardDateTime = 'E MMM dd yyyy hh:mm:ss a',
  StandardDateTimeWithOffset = 'E MMM dd yyyy hh:mm:ss a z',
}

type Props = {
  dateString: string
  dateTimeFormat?: DateTimeFormat
  showUtcTooltip?: boolean
  className?: string
}

export const DateText: React.FC<Props> = ({
  dateString,
  dateTimeFormat = DateTimeFormat.StandardDateTime,
  className,
}) => {
  try {
    const date = parseISO(dateString)
    const formattedDateText = format(date, dateTimeFormat)
    const isoDate = date.toISOString()

    return (
      <span title={isoDate}>
        <span className={className}>{formattedDateText}</span>
      </span>
    )
  } catch (error) {
    console.error(error)

    return null
  }
}
