import React from 'react'
import { observer } from 'mobx-react-lite'
import { ButtonOutline } from '~/common/ui/Buttons'
import RootStore from '~/stores/root/RootStore'
import { useField, Formik, FormikProps, Form } from 'formik'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  FormTextInput,
  FormSectionTitle,
  FormSelect,
  RadioWithLabel,
  RadioGroup,
  FormField,
} from '~/common/ui/Forms'
import {
  FIELDS,
  AWS_SUPPORT_TYPES,
  CUSTOMER_TYPES,
  FIELD_HELPER_TEXTS,
  ORG_FORM_INITIAL_STATE,
} from '~/common/constantsProvision'

import {
  computeFieldStyleProps,
  renderSelectHelperTextV2,
  validateRequired,
  parseAndSetField,
} from '~/common/utils/provision'

type ComponentProps = {
  selectedCustomerId?: string
  marketplaceSignupValue?: boolean
}

type Props = FormikProps<typeof ORG_FORM_INITIAL_STATE> & ComponentProps

const AddOrgForm: React.FC<Props> = observer(
  ({ selectedCustomerId, handleSubmit }) => {
    const { billingroot, customer, organisation } = React.useContext(RootStore)

    const [
      customerIdField,
      { error: customerIdError, touched: customerIdTouched },
    ] = useField({ name: FIELDS.CUSTOMER, validate: validateRequired })

    const selectedCustomer = customer.list.find(
      (customer) => customer.id === customerIdField.value,
    )
    const customerTypeDirect =
      selectedCustomer && selectedCustomer.type === CUSTOMER_TYPES.DIRECT

    const defaultOrg =
      selectedCustomer &&
      organisation
        .list(selectedCustomer.id)
        .find(({ name }) => name === 'default')

    const [marketplaceSignupField, _, marketplaceFieldHelperProps] = useField({
      name: FIELDS.MARKETPLACE_SIGNUP,
      validate: validateRequired,
    })

    const [
      billingRootField,
      { touched: billingRootTouched, error: billingRootError },
    ] = useField({ name: FIELDS.BILLING_ROOT, validate: validateRequired })

    const [orgAliasField, { touched: orgAliasTouched, error: orgAliasError }] =
      useField({ name: FIELDS.ORG_ALIAS, validate: validateRequired })

    const [
      emailTemplateField,
      { touched: emailTemplateTouched, error: emailTemplateError },
    ] = useField({
      name: FIELDS.EMAIL_TEMPLATE,
      validate: validateRequired,
    })

    const [
      awsSupportTypeField,
      { error: awsSupportTypeError, touched: awsSupportTypeTouched },
    ] = useField({ name: FIELDS.AWS_SUPPORT_TYPE, validate: validateRequired })

    const [partnerSupportField] = useField({
      name: FIELDS.PARTNER_SUPPORT,
      validate: !customerTypeDirect ? validateRequired : undefined,
    })

    const [salesforceIdField] = useField({
      name: FIELDS.SALESFORCE_ID,
      validate: validateRequired,
    })

    const [spotlightIdField] = useField({ name: FIELDS.SPOTLIGHT_ID })

    const [
      idamUserEmailField,
      { touched: idamUserEmailTouched, error: idamUserEmailError },
    ] = useField({ name: FIELDS.IDAM_USER_EMAIL, validate: validateRequired })

    const [
      idamUserFirstNameField,
      { touched: idamUserFirstNameTouched, error: idamUserFirstNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_FIRST_NAME,
      validate: validateRequired,
    })

    const [
      idamUserLastNameField,
      { touched: idamUserLastNameTouched, error: idamUserLastNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_LAST_NAME,
      validate: validateRequired,
    })

    React.useEffect(() => {
      billingroot.fetch('UNUSED')
      customer.fetch()
    }, [])

    React.useEffect(() => {
      const fetchOrgs = async () => {
        if (selectedCustomer) {
          await organisation.fetch(selectedCustomer.id)
          const defaultOrg =
            selectedCustomer &&
            organisation
              .list(selectedCustomer.id)
              .find(({ name }) => name === 'default')
          if (defaultOrg) {
            marketplaceFieldHelperProps.setValue(
              Boolean(Number(defaultOrg.marketplaceSignup)),
            )
            // marketplaceMetaProps.touched
          }
        }
      }
      fetchOrgs()
    }, [selectedCustomer])

    return (
      <Form onSubmit={handleSubmit}>
        <FormSelect
          disabled={typeof selectedCustomerId === 'string'}
          label="Customer"
          name={customerIdField.name}
          onBlur={customerIdField.onBlur}
          onChange={customerIdField.onChange}
          required={true}
          value={customerIdField.value}
          {...renderSelectHelperTextV2(customerIdError, customerIdTouched)}
        >
          <option disabled value="">
            Select a customer
          </option>
          {customer.list.map(({ id, name }) => (
            <option value={id} key={id}>
              {name}
            </option>
          ))}
        </FormSelect>

        {customerTypeDirect === false ? (
          <FormField
            id={partnerSupportField.name}
            label="Partner Support?"
            required={false}
          >
            <RadioGroup>
              <RadioWithLabel
                checked={partnerSupportField.value === true}
                label="Yes"
                name={partnerSupportField.name}
                onChange={parseAndSetField(partnerSupportField.onChange)}
                value={true}
              />

              <RadioWithLabel
                checked={partnerSupportField.value === false}
                label="No"
                name={partnerSupportField.name}
                onChange={parseAndSetField(partnerSupportField.onChange)}
                value={false}
              />
            </RadioGroup>
          </FormField>
        ) : null}

        {defaultOrg ? (
          <FormField
            id={marketplaceSignupField.name}
            label="AWS Marketplace Signup?"
            required={false}
          >
            <RadioGroup>
              <RadioWithLabel
                checked={marketplaceSignupField.value}
                label="Yes"
                name={marketplaceSignupField.name}
                onChange={parseAndSetField(marketplaceSignupField.onChange)}
                value={true}
              />

              <RadioWithLabel
                checked={!marketplaceSignupField.value}
                label="No"
                name={marketplaceSignupField.name}
                onChange={parseAndSetField(marketplaceSignupField.onChange)}
                value={false}
              />
            </RadioGroup>
          </FormField>
        ) : null}

        <FormSelect
          label="Billing Root"
          name={billingRootField.name}
          onBlur={billingRootField.onBlur}
          onChange={billingRootField.onChange}
          required={true}
          value={billingRootField.value}
          {...renderSelectHelperTextV2(billingRootError, billingRootTouched)}
        >
          <option disabled value="">
            Select a billing root
          </option>
          {billingroot.poolList().map((root) => (
            <option value={root.awsAccountId} key={root.awsAccountId}>
              {root.email}
            </option>
          ))}
        </FormSelect>

        <FormTextInput
          id={orgAliasField.name}
          label="Alias"
          required={true}
          {...orgAliasField}
          {...computeFieldStyleProps(
            orgAliasError,
            orgAliasTouched,
            FIELD_HELPER_TEXTS[FIELDS.ORG_ALIAS],
          )}
        />

        <FormTextInput
          id={emailTemplateField.name}
          label="Email domain template"
          required={true}
          {...emailTemplateField}
          {...computeFieldStyleProps(
            emailTemplateError,
            emailTemplateTouched,
            'e.g. aws-${Stax::AccountId}@fakecompany.com',
          )}
        />

        <FormSelect
          label="AWS Support Type"
          name={awsSupportTypeField.name}
          onBlur={awsSupportTypeField.onBlur}
          onChange={awsSupportTypeField.onChange}
          required={true}
          value={awsSupportTypeField.value}
          {...renderSelectHelperTextV2(
            awsSupportTypeError,
            awsSupportTypeTouched,
          )}
        >
          {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType, index) => (
            <option value={awsSupportType} key={index}>
              {awsSupportType}
            </option>
          ))}
        </FormSelect>

        <FormTextInput
          hasError={false}
          helperText={FIELD_HELPER_TEXTS[FIELDS.SPOTLIGHT_ID]}
          id={spotlightIdField.name}
          label="Spotlight External ID"
          required={false}
          {...spotlightIdField}
        />

        <FormTextInput
          hasError={false}
          helperText={FIELD_HELPER_TEXTS[FIELDS.SALESFORCE_ID]}
          id={salesforceIdField.name}
          label="Salesforce ID"
          required={true}
          {...salesforceIdField}
        />

        <FormSectionTitle>First IDAM User</FormSectionTitle>

        <FormTextInput
          id={idamUserEmailField.name}
          label="Email"
          required={true}
          {...idamUserEmailField}
          {...computeFieldStyleProps(
            idamUserEmailError,
            idamUserEmailTouched,
            FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_EMAIL],
          )}
        />

        <FormTextInput
          id={idamUserFirstNameField.name}
          label="First Name"
          required={true}
          {...idamUserFirstNameField}
          {...computeFieldStyleProps(
            idamUserFirstNameError,
            idamUserFirstNameTouched,
            FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_FIRST_NAME],
          )}
        />

        <FormTextInput
          id={idamUserLastNameField.name}
          label="Last Name"
          required={true}
          {...idamUserLastNameField}
          {...computeFieldStyleProps(
            idamUserLastNameError,
            idamUserLastNameTouched,
            FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_LAST_NAME],
          )}
        />

        <ButtonOutline type="submit">Create Organisation</ButtonOutline>
      </Form>
    )
  },
)

const ProvisionAddOrg: React.FC<RouteComponentProps & ComponentProps> = ({
  history,
  selectedCustomerId,
}) => {
  const { provision } = React.useContext(RootStore)

  const handleSubmit = async (values: typeof ORG_FORM_INITIAL_STATE) => {
    const payload = {
      CustomerId: values.customerId,
      BillingAccount: values.billingRoot,
      OrgAlias: values.orgAlias,
      MarketplaceSignup: values.marketplaceSignup,
      AwsSupportType: values.awsSupportType,
      IdamUserEmail: values.idamUserEmail,
      IdamUserFirstName: values.idamUserFirstName,
      IdamUserLastName: values.idamUserLastName,
    }

    // Optional payload keys
    if (values.spotlightId)
      Object.assign(payload, { SpotlightId: values.spotlightId })
    if (values.emailTemplate)
      Object.assign(payload, { EmailTemplate: values.emailTemplate })
    if (values.salesforceId)
      Object.assign(payload, { SalesforceId: values.salesforceId })

    if (values.partnerSupport) {
      Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
    } else {
      Object.assign(payload, { AwsPartnerSupport: false })
    }

    const res = await provision.add_organisation(payload)

    if (res.status === 200) {
      window.alert('Success! Factory execution started')
      history.push(
        selectedCustomerId ? `/customers/${selectedCustomerId}` : '/provision',
      )
    } else {
      window.alert('An unknown error occurred')
    }
  }

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        ...ORG_FORM_INITIAL_STATE,
        customerId: selectedCustomerId || '',
      }}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <AddOrgForm selectedCustomerId={selectedCustomerId} {...formikProps} />
      )}
    </Formik>
  )
}

export default withRouter(ProvisionAddOrg)
