import { ButtonSolid, ButtonOutline } from '~/common/ui/Buttons'

const PageShowcase = () => {
  return (
    <div className="p-8">
      <h1>Buttons</h1>
      <table className="border-separate border-spacing-2">
        <thead>
          <tr>
            <th />
            <th>Solid</th>
            <th>Outline</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Primary</td>
            <td>
              <ButtonSolid>Click me</ButtonSolid>
            </td>
            <td>
              <ButtonOutline>Click me</ButtonOutline>
            </td>
          </tr>
          <tr>
            <td>Primary disabled</td>
            <td>
              <ButtonSolid disabled>Click me</ButtonSolid>
            </td>
            <td>
              <ButtonOutline disabled>Click me</ButtonOutline>
            </td>
          </tr>
          <tr>
            <td>Secondary</td>
            <td>
              <ButtonSolid variant="Secondary">Click me</ButtonSolid>
            </td>
            <td>
              <ButtonOutline variant="Secondary">Click me</ButtonOutline>
            </td>
          </tr>
          <tr>
            <td>Secondary disabled</td>
            <td>
              <ButtonSolid variant="Secondary" disabled>
                Click me
              </ButtonSolid>
            </td>
            <td>
              <ButtonOutline variant="Secondary" disabled>
                Click me
              </ButtonOutline>
            </td>
          </tr>
          <tr>
            <td>Danger</td>
            <td>
              <ButtonSolid variant="Danger">Click me</ButtonSolid>
            </td>
            <td>
              <ButtonOutline variant="Danger">Click me</ButtonOutline>
            </td>
          </tr>
          <tr>
            <td>Danger disabled</td>
            <td>
              <ButtonSolid variant="Danger" disabled>
                Click me
              </ButtonSolid>
            </td>
            <td>
              <ButtonOutline variant="Danger" disabled>
                Click me
              </ButtonOutline>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PageShowcase
