export const openInNewTabConfirm = (url: string) => {
  const confirmation = window.prompt(
    `This account is owned by the customer, not Stax.\n
You should not log into this account without permission from the customer.\n
Logging into this account will trigger an INCIDENT.\n
Are you sure you want to log into this customer account?\n
If you are sure, type 'YES' below:\n`,
  )

  if (confirmation === 'YES') {
    openInNewTab(url)
  }
}

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
