import React from 'react'
import { useHistory } from 'react-router'
import NavItem from './Navigation/NavItem'
import NavLogo from './Navigation/NavLogo'
import NavAccountMenu from './Navigation/NavAccountMenu'
import { NavLinks } from './Navigation/NavLinks'
import { routes } from 'routes'
import { AmplifyAuthConsumer } from '~/wrappers/AmplifyListener'
import { HocConsumerProps, User } from '~/wrappers/AmplifyListener.d'
import { stripFirstLetters } from '~/common/utils/StringUtil'

import { STAGES, currentStageId } from '~/common/utils/stages'

type Props = {
  pageTitle: string
  pageActions?: React.ReactNode
}

const Navigation = (props: Props) => {
  const history = useHistory()
  const [compact, setCompact] = React.useState(false)

  const logout = () => {
    console.debug('push signout')
    history.push(routes.auth.SIGN_OUT)
  }

  const currentStage = currentStageId()

  const versentEmailEnding = '@versent.com.au'
  const oktaEmailEnding = '@stax.io'
  const initials = (user: User) => {
    if (user.name) {
      return stripFirstLetters(
        user.name.replace(versentEmailEnding, '').replace(oktaEmailEnding, ''),
      )
    }
    return ''
  }
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || 0
    if (scrollTop > 0 && compact === false) {
      setCompact(true)
    } else if (scrollTop === 0 && compact === true) {
      setCompact(false)
    }
  }

  // console.log({ currentStage, STAGES })

  return (
    <div className="t-nav-container sticky top-0 z-10">
      <div className="bg-slate-800 flex justify-center">
        <div
          className="flex justify-between items-center"
          style={{ width: 'var(--app-width)' }}
        >
          <div className="flex">
            <NavLogo compact={compact} />
            <nav className="t-nav-item-list flex space-x-4 ml-8">
              {NavLinks.map((navLink, index) => (
                <NavItem {...navLink} compact={compact} key={navLink.name} />
              ))}
            </nav>
          </div>

          <div className="flex space-x-4">
            <select
              value={currentStage}
              className="text-white border-slate-500 px-4"
              onChange={(event) => {
                const stage = STAGES.find(
                  (stage) => stage.stage === event.target.value,
                )

                if (!stage) return console.error('oh no') // should never happen

                if (stage.stage === currentStage) return // no worries, stay put

                window.location.assign(stage.link)
              }}
            >
              {STAGES.map(({ stage }) => (
                <option key={stage} value={stage}>
                  {stage}
                </option>
              ))}
            </select>

            <button
              onClick={logout}
              className="text-white cursor-pointer hover:underline ml-3"
              type="button"
            >
              Sign out
            </button>

            <AmplifyAuthConsumer>
              {(state: HocConsumerProps) => (
                <NavAccountMenu
                  name={
                    state.authState.user ? state.authState.user.name : undefined
                  }
                  region={window.AWS_CONFIG.JumaAuth.region}
                  userId={
                    state.authState.user ? state.authState.user.id : undefined
                  }
                  roles={
                    state.authState.user
                      ? state.authState.user.idamRoles
                      : undefined
                  }
                  initials={
                    state.authState.user
                      ? initials(state.authState.user)
                      : undefined
                  }
                />
              )}
            </AmplifyAuthConsumer>
          </div>
        </div>
      </div>
      <div className="bg-white flex justify-center border-b border-b-slate-200">
        <div
          className="p-4 flex items-center justify-between"
          style={{ width: 'var(--app-width)' }}
        >
          {props.pageTitle}
          {props.pageActions}
        </div>
      </div>
    </div>
  )
}

export default Navigation
