/**
 * Don't use these any more, see ./services.ts for better API invoking
 */

import { API } from 'aws-amplify'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import { API_NAME } from './constants'

export enum Status {
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
  Idle = 'idle',
}

/**
 * A method to call for easy GET requests in your stores
 * @param endpoint
 * @deprecated
 */
export const get = async (endpoint: string, data = {}): Promise<any> =>
  camelcaseKeysDeep(
    await API.get(
      API_NAME,
      endpoint,
      // NOTE: params in this object get passed down to axios config
      {
        data,
        response: true,
      },
    ),
  )

/**
 * A method to call for easy POST requests in your stores
 * @param endpoint
 * @deprecated
 */
export const post = async (endpoint: string, body = {}): Promise<any> =>
  camelcaseKeysDeep(
    await API.post(API_NAME, endpoint, {
      body,
      response: true,
    })
      .then((response) => response)
      .catch((error) => error.response.data),
  )

/**
 * A method to call for easy PUT requests in your stores
 * @param endpoint
 * @deprecated
 */
export const put = async (endpoint: string, body = {}): Promise<any> =>
  camelcaseKeysDeep(
    await API.put(API_NAME, endpoint, {
      body,
      response: true,
    })
      .then((response) => response)
      .catch((error) => error.response.data),
  )

/**
 * @deprecated
 */
export const del = async (endpoint: string, body = {}): Promise<any> =>
  camelcaseKeysDeep(
    await API.del(API_NAME, endpoint, {
      body,
      response: false,
    }),
  )

export default {
  get,
  post,
  put,
  del,
}
