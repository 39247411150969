import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, RouteComponentProps, useHistory } from 'react-router'
import { ButtonOutline } from '~/common/ui/Buttons'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import { Loading } from '~/common/ui/Loading'
import { useServices, Service } from '~/common/api'
import { Organisation } from '~/stores/organisations/types'
import { DateText } from '~/common/ui/DateText'
import { omit } from 'lodash'
import { Status } from '~/common/ui/Status'
import {
  routes,
  configureSecurityHubLink,
  configureRegionsLink,
} from '../routes'
import { ConfigureSecurityHubModal } from './CustomerServiceList/ConfigureSecurityHubModal'
import { ConfigureRegionsModal } from './CustomerServiceList/ConfigureRegionsModal'

const headers = [
  { title: 'Service' },
  { title: 'Status' },
  { title: 'Created' },
  { title: 'Info' },
  { title: 'Actions' },
]

const CustomerServiceList: React.FC<RouteComponentProps<{ id: string }>> =
  observer(() => {
    const { organisation: organisationStore } = React.useContext(RootStore)

    const organisationId = (organisationStore.current as Organisation)?.id || ''
    const customerId =
      (organisationStore.current as Organisation)?.customerId || ''

    const { services = [], loading, error } = useServices(organisationId || '')

    console.log({ services, loading, error })

    if (!organisationStore.current) {
      return <NoResults title="No organisation selected" />
    }

    if (organisationStore.current.status === 'SUSPENDED') {
      return <NoResults title="Organisation is suspended" />
    }

    if (services.length === 0) {
      return <NoResults title="No services found" />
    }

    if (organisationStore.isLoading || loading) return <Loading />

    const Actions = ({ service }: { service: Service }) => {
      if (service.Service === 'SECURITYHUB') {
        return (
          <SecurityHubActions
            service={service}
            organisationId={organisationId}
            customerId={customerId}
          />
        )
      } else if (service.Service === 'REGIONS') {
        return (
          <RegionsActions
            service={service}
            organisationId={organisationId}
            customerId={customerId}
          />
        )
      }
      return null
    }

    const Info = ({ service }: { service: Service }) => {
      if (
        service.Service === 'SECURITYHUB' &&
        typeof service.Configuration === 'object'
      ) {
        return (
          <Fragment>
            <b>Standards: </b>
            <br />
            <ul>
              {Object.entries(
                omit(service.Configuration.Standards, 'AWS_REGIONS'),
              ).map(([standard, value]) => (
                <li key={standard} className="pb-1">
                  <span>{standard}</span>
                  <br />
                  <span className="text-slate-500">{value ? 'On' : 'Off'}</span>
                </li>
              ))}
            </ul>
            <b>Regions: </b>
            <div>{service.Configuration.Standards.AWS_REGIONS?.join(', ')}</div>
          </Fragment>
        )
      }
      return <Fragment>{JSON.stringify(service.Configuration)}</Fragment>
    }

    return (
      <Fragment>
        <Route
          exact
          path={routes.customer.SERVICES_CONFIGURE_SECURITY_HUB}
          render={() => (
            <ConfigureSecurityHubModal
              organisationId={organisationId}
              customerId={customerId}
              orgAlias={organisationStore.current?.alias || ''}
            />
          )}
        />
        <Route
          exact
          path={routes.customer.SERVICES_CONFIGURE_REGIONS}
          render={() => (
            <ConfigureRegionsModal
              organisationId={organisationId}
              customerId={customerId}
              orgAlias={organisationStore.current?.alias || ''}
            />
          )}
        />
        <table className="DataTable">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.title}>{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service.Id}>
                <td>{service.Service}</td>
                <td>
                  <Status status={service.Status} />
                </td>
                <td>
                  <DateText dateString={service.CreatedTS} />
                </td>
                <td width={'20%'}>
                  <Info service={service} />
                </td>
                <td>
                  <Actions service={service} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    )
  })

const SecurityHubActions = ({
  service,
  organisationId,
  customerId,
}: {
  service: Service
  organisationId: string
  customerId: string
}) => {
  const history = useHistory()
  const configureSecurityHub = () =>
    history.push(configureSecurityHubLink(customerId, organisationId))

  if (service.Service !== 'SECURITYHUB') return null

  if (service.Status === 'ACTIVE') {
    return (
      <ButtonOutline onClick={configureSecurityHub} variant="Danger">
        Configure
      </ButtonOutline>
    )
  }

  return <div>Users must self-service to activate Security Hub</div>
}

const RegionsActions = ({
  service,
  organisationId,
  customerId,
}: {
  service: Service
  organisationId: string
  customerId: string
}) => {
  const history = useHistory()
  const configureRegions = () =>
    history.push(configureRegionsLink(customerId, organisationId))

  if (service.Service !== 'REGIONS') return null

  return (
    <ButtonOutline onClick={configureRegions} variant="Danger">
      Configure
    </ButtonOutline>
  )
}

export default CustomerServiceList
