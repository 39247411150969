import React from 'react'
import { routes } from '~/routes'
import { Switch, Route } from 'react-router'
import PageProvisionAddBillingRoot from './PageProvisionAddBillingRoot'
import PageProvisionAddCustomer from './PageProvisionAddCustomer'
import PageProvisionAddOrg from './PageProvisionAddOrg'
import PageProvisionVerification from './PageProvisionVerification'
import RootStore from '~/stores/root/RootStore'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ProvisionBillingRoots from '~/widgets/ProvisionBillingRoots'
import ProvisionBillingRootPool from '~/widgets/ProvisionBillingRootPool'
import Can, { Role } from '~/common/ui/Can'
import { observer } from 'mobx-react-lite'
import Page from '~/common/ui/Page'
import { ButtonOutline } from '~/common/ui/Buttons'

const PageProvision: React.FC<RouteComponentProps> = observer(({ history }) => {
  const { billingroot } = React.useContext(RootStore)

  React.useEffect(() => {
    billingroot.fetch('UNUSED')
  }, [])

  const numActiveBillingRoots = billingroot.poolList().length

  const actions = (
    <div className="t-provision-links flex space-x-2">
      <Can
        requiredRole={Role.deployer}
        yes={
          <ButtonOutline onClick={() => history.push(routes.provision.ADDROOT)}>
            + New Billing Root
          </ButtonOutline>
        }
        no={
          <ButtonOutline
            title="You aren't allowed to create billing roots"
            disabled={true}
          >
            + New Billing Root
          </ButtonOutline>
        }
      />
      <Can
        requiredRole={Role.customersuccess}
        yes={
          <ButtonOutline
            onClick={() => history.push(routes.provision.VERIFY)}
            title="Verify Onboarding Status"
          >
            Verify Onboarding
          </ButtonOutline>
        }
        no={
          <ButtonOutline
            title="You aren't allowed to verify provisioned installations"
            disabled={true}
          >
            Verify Onboarding
          </ButtonOutline>
        }
      />
      <Can
        requiredRole={Role.deployer}
        yes={
          <ButtonOutline
            title={
              numActiveBillingRoots === 0
                ? 'No available billing roots'
                : 'Create a customer'
            }
            onClick={() =>
              numActiveBillingRoots > 0 &&
              history.push(routes.provision.ADDCUST)
            }
            disabled={numActiveBillingRoots === 0}
          >
            + New Customer
          </ButtonOutline>
        }
        no={
          <ButtonOutline
            title="You aren't allowed to create customers"
            disabled={true}
          >
            + New Customer
          </ButtonOutline>
        }
      />
      <Can
        requiredRole={Role.deployer}
        yes={
          <ButtonOutline
            title={
              numActiveBillingRoots === 0
                ? 'No available billing roots'
                : 'Create an organisation'
            }
            onClick={() =>
              numActiveBillingRoots > 0 && history.push(routes.provision.ADDORG)
            }
            disabled={numActiveBillingRoots === 0}
          >
            + New Organisation
          </ButtonOutline>
        }
        no={
          <ButtonOutline
            title="You aren't allowed to create organisations"
            disabled={true}
          >
            + New Organisation
          </ButtonOutline>
        }
      />
    </div>
  )

  return (
    <Switch>
      <Route
        exact
        path={routes.provision.ADDORG}
        component={PageProvisionAddOrg}
      />
      <Route
        exact
        path={routes.provision.ADDROOT}
        component={PageProvisionAddBillingRoot}
      />
      <Route
        exact
        path={routes.provision.ADDCUST}
        component={PageProvisionAddCustomer}
      />
      <Route
        exact
        path={routes.provision.VERIFY}
        component={PageProvisionVerification}
      />
      <Route exact path={routes.provision.INDEX}>
        <Page title="Provision" actions={actions}>
          <h2 className="mt-4 font-semibold">Available Billing Roots</h2>
          <ProvisionBillingRootPool />

          <h2 className="mt-4 font-semibold">All Billing Roots</h2>
          <ProvisionBillingRoots />
        </Page>
      </Route>
    </Switch>
  )
})

export default withRouter(PageProvision)
