import React from 'react'
import ComponentList from '~/widgets/ComponentList'
import Page from '~/common/ui/Page'

type Props = {}

const PageComponents: React.FC<Props> = () => {
  return (
    <Page title="Components">
      <ComponentList />
    </Page>
  )
}

export default PageComponents
