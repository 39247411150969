import ProvisionAddBillingRoot from '~/widgets/ProvisionAddBillingRoot'
import Page from '~/common/ui/Page'
import { NarrowContainerWithPadding } from '~/common/ui/Panels'

const PageProvisionAddBillingRoot = () => (
  <Page title="Provision">
    <NarrowContainerWithPadding>
      <h2 className="text-lg pb-4">
        Add New Billing Root to Stax Installation
      </h2>
      <ProvisionAddBillingRoot />
    </NarrowContainerWithPadding>
  </Page>
)

export default PageProvisionAddBillingRoot
