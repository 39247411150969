import useSWR from 'swr'

// axios.defaults.baseURL = 'https://adminapi.dev-master.core.dev.juma.cloud/'

export function useServices(organisationId: string) {
  const { data, error } = useSWR({
    url: `/organisations/${organisationId || ''}/configuration`,
  })

  return {
    services: data?.ServiceConfigurations as Service[],
    loading: !error && !data,
    error,
  }
}

export type Service = {
  Configuration: Record<string, any>
  CreatedBy: string | null
  CreatedTS: string
  ModifiedTS: string
  Enabled: boolean
  Id: string
  OrganisationId: string
  Service: 'SECURITYHUB' | string
  Status: string
  UserTaskId: string
}
