import memoize from 'lodash/memoize'
import { get, post } from '~/common/api/apiLegacy'
import { Component } from '~/stores/components/types'

interface ComponentApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
  deploy: (endpoint: string, id: string) => Promise<any>
}

class ComponentService implements ComponentApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }

  // Re-promote a component with the specific deployment ID
  deploy = (endpoint: string, id: string) => {
    return post(endpoint, { DeploymentId: id })
  }

  // Update a value for a component
  autodeploy = (endpoint: string, component: Component, value: boolean) => {
    return post(endpoint, { Components: { [component.name]: value } })
  }

  lock = (
    endpoint: string,
    component: Component,
    lock: boolean,
    comment: string,
  ) => {
    // console.log(`Update ${endpoint} with ${lock} and ${comment}`)
    return post(endpoint, {
      Components: [{ Name: component.name, Locked: lock, Comment: comment }],
    })
  }
}

export default ComponentService
