import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from '~/common/ui/Switch'
import { ButtonOutline } from '~/common/ui/Buttons'
import { InlineControlAndLabel, FormTextInput } from '~/common/ui/Forms'
import RootStore from '~/stores/root/RootStore'
import { Component } from '~/stores/components/types'
import Can, { Role } from '~/common/ui/Can'

import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from '~/common/ui/Modals'

interface State {
  comment: string | null
  enabled: boolean
  open: boolean
}

type Props = {
  className?: string
  enabled: boolean
  component: Component
}

const ComponentLockSwitch = observer(({ enabled, ...restProps }: Props) => {
  const { component } = React.useContext(RootStore)
  const [state, setState] = React.useState<State>({
    comment: '',
    enabled: enabled,
    open: false,
  })

  const handleChange = (checked: boolean) => {
    setState({ ...state, open: true })
  }

  const toggleLock = () => {
    if (state.comment) {
      component.lock(restProps.component, !state.enabled, state.comment)
      setState({
        ...state,
        enabled: !state.enabled,
        open: false,
        comment: state.comment,
      })
    } else {
      setState({ ...state, open: false })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const updateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, comment: event.target.value })
  }

  return (
    <Can
      requiredRole={Role.deployer}
      yes={
        <React.Fragment>
          <InlineControlAndLabel label="Lock Deploy">
            <Switch
              checked={state.enabled}
              disabled={false}
              onChange={handleChange}
            />
          </InlineControlAndLabel>

          <Modal
            isOpen={state.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <ModalTitle id="form-dialog-title">Lock</ModalTitle>
            {!state.enabled ? (
              <ModalContent>
                <p className="pb-2">
                  To lock this component a comment is required.
                </p>
                <FormTextInput
                  id="comment"
                  label="comment"
                  onChange={updateText}
                  required
                  value={state.comment ? state.comment : ''}
                />
              </ModalContent>
            ) : (
              <ModalContent>
                <p className="pb-2">
                  To unlock this component a comment is required.
                </p>
                c
                <FormTextInput
                  id="comment"
                  label="comment"
                  onChange={updateText}
                  required
                  value={state.comment ? state.comment : ''}
                />
              </ModalContent>
            )}
            <ModalFooter>
              <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>
              {state.enabled ? (
                <ButtonOutline onClick={toggleLock}>Unlock</ButtonOutline>
              ) : (
                <ButtonOutline onClick={toggleLock}>Lock</ButtonOutline>
              )}
            </ModalFooter>
          </Modal>
        </React.Fragment>
      }
      no={
        <InlineControlAndLabel label="Lock Deploy">
          <Switch checked={state.enabled} disabled={true} />
        </InlineControlAndLabel>
      }
    />
  )
})

export default ComponentLockSwitch
