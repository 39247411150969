import React from 'react'
import { observer } from 'mobx-react-lite'
import { Account } from '~/stores/accounts/types'
import RootStore from '~/stores/root/RootStore'
import { Loading } from '~/common/ui/Loading'
import NoResults from '~/common/ui/NoResults'
import { Panel } from '~/common/ui/Panels'

const getTableRows = (accounts: Account[]) => {
  return accounts.map((item) => {
    return {
      key: item.id,
      cells: [item.name, item.awsAccountId, item.email],
    }
  })
}

const headers = [{ title: 'Name' }, { title: 'Account ID' }, { title: 'Email' }]

const ProvisionBillingRootPool = observer(() => {
  const { billingroot } = React.useContext(RootStore)

  React.useEffect(() => {
    billingroot.fetch('UNUSED')
  }, [])

  const accounts = billingroot.poolList()
  const rows = getTableRows(accounts)

  if (billingroot.isLoading) return <Loading />

  if (rows.length < 1)
    return (
      <Panel>
        <NoResults title="No Available Billing Roots" />
      </Panel>
    )

  return (
    <Panel>
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.key}>
              {row.cells.map((cell, index) => (
                <td key={index}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  )
})

export default ProvisionBillingRootPool
