import { action, makeObservable } from 'mobx'
import { ProvisionApi } from '~/stores/provision/ProvisionApi'
import { RootStore } from '~/stores/root/RootStore'

/* ProvisionStore POTS to the API to handle provisioning tasks\
 */

export default class BillingRootStore {
  root: RootStore
  api: ProvisionApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: ProvisionApi, endpoint = '/provision') {
    makeObservable(this, {
      add_root: action,
      add_customer: action,
      add_organisation: action,
      verify_onboarding: action,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  add_root = (body: {}) => {
    return this.api.invoke('/provision/billingroot', body)
  }
  add_customer = (body: {}) => {
    return this.api.invoke('/provision/customer', body)
  }
  add_organisation = (body: {}) => {
    return this.api.invoke('/provision/organisation', body)
  }
  verify_onboarding = (body: {}) => {
    return this.api.invoke('/provision/onboardingvalidation', body)
  }
}
