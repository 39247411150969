import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { WorkloadApi } from './WorkloadApi'
import { RootStore } from '~/stores/root/RootStore'
import { Status } from '~/common/api/apiLegacy'
import { Workload } from './types'

type OrgId = string

export default class WorkloadStore {
  root: RootStore

  api: WorkloadApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: WorkloadApi, endpoint = '/workloads') {
    makeObservable(this, {
      status: observable,
      workloads: observable,
      fetch: action,
      invoke: action,
      isLoading: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  status: Status = Status.Idle

  workloads: ObservableMap<OrgId, Workload[]> = observable.map()

  fetch = async (orgId: string, force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(`${this.endpoint}/${orgId}`, force)
      this.workloads.set(orgId, res.data.workloads)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  invoke = (workloadId: string) => {
    return this.api.invoke('/factory/workload', workloadId)
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  list = (orgId: OrgId) => {
    return this.workloads.get(orgId) || []
  }
}
