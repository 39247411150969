import React from 'react'
import { buildOAuthUrl, Provider } from '~/common/utils/auth/oAuthURL'
import { OAuthConfig } from '~/common/config'
import { ButtonSolid } from '~/common/ui/Buttons'

interface Props {
  redirect: Function
}

class PageLogin extends React.Component<Props> {
  handleSignIn = (provider: Provider) => {
    const clientId = window.AWS_CONFIG.JumaAuth.userPoolWebClientId || ''
    const url = buildOAuthUrl(OAuthConfig.oauth, clientId, provider)
    window.location.assign(url)
    this.props.redirect()
  }

  render = () => {
    return (
      <div
        className="flex items-center justify-center"
        style={{ height: '100vh', background: '#EBEFF1' }}
      >
        <div className="bg-white p-16 rounded">
          <div className="flex justify-center pb-6">
            <img
              style={{
                width: '6rem',
                height: '6rem',
              }}
              src="/images/stax-icon-black.svg"
            />
          </div>

          <div className="font-semibold pb-6 text-center">
            Log in to Juma Admin
          </div>

          <ButtonSolid onClick={() => this.handleSignIn(Provider.OKTA)}>
            Log in with Okta
          </ButtonSolid>
        </div>
      </div>
    )
  }
}
export default PageLogin
