import React from 'react'
import { observer } from 'mobx-react-lite'
import { debounce } from 'dettle'
import ComponentCatalogue from './ComponentList/ComponentCatalogue'
import RootStore from '~/stores/root/RootStore'
import { routes } from '../routes'
import { TextInput } from '~/common/ui/Forms'
import { Panel } from '~/common/ui/Panels'

const ComponentList = observer(() => {
  const { component } = React.useContext(RootStore)

  const [queryInput, setQueryInput] = React.useState('')
  const [appliedQuery, setAppliedQuery] = React.useState('')

  const setQueryDebounced = React.useCallback(
    debounce((query: string) => {
      setAppliedQuery(query)
    }, 200),
    [],
  )

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value
      setQueryInput(query)
      setQueryDebounced(query)
    },
    [setQueryDebounced],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: initial fetch, called once
  React.useEffect(() => {
    component.fetch()
  }, [])

  return (
    <React.Fragment>
      <Panel className="p-3 mb-3 rounded shadow-sm">
        <TextInput
          className="w-80"
          placeholder="Search components by name..."
          value={queryInput}
          onChange={handleChange}
        />
      </Panel>
      <ComponentCatalogue
        appliedQuery={appliedQuery}
        components={component.list}
        route={routes.components.INDEX}
        isLoading={component.isLoading}
      />
    </React.Fragment>
  )
})

export default ComponentList
