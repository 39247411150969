import memoize from 'lodash/memoize'
import { get, post } from '~/common/api/apiLegacy'

export interface WorkloadApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
  invoke: (endpoint: string, workloadId: string) => Promise<any>
}

class WorkloadService implements WorkloadApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }

  invoke = (endpoint: string, workloadId: string) => {
    return post(endpoint, { Id: workloadId })
  }
}

export default WorkloadService
