import { observable, action, computed, makeObservable } from 'mobx'
import { AxiosError } from 'axios'
import { isEmpty } from 'lodash'

import { RootStore } from '~/stores/root/RootStore'

export default class AuthStore {
  root: RootStore | undefined

  constructor(rootStore: RootStore | undefined = undefined) {
    makeObservable(this, {
      error: observable,
      hasError: computed,
      setError: action,
    })

    this.root = rootStore
  }

  error: Error | undefined

  get hasError() {
    return !!this.error
  }

  setError(err: any) {
    this.error = err
  }

  /**
   * Pass an error from axios/aws-amplify, this will tell you if the user was unauthenticated
   * @param error: AxiosError
   */
  isAuthError = (error: AxiosError) =>
    !isEmpty(error.response) &&
    (error.response!.status === 401 || error.response!.status === 403)
}
