import React from 'react'
import className from 'classnames'
import ComponentDiff from './ComponentDiff'
import ComponentTime from './ComponentTime'
import ComponentVersion from './ComponentVersion'
import { Deployment } from '~/stores/components/types'
import RootStore from '~/stores/root/RootStore'
import { Status } from '~/stores/components/types'
import Can, { Role } from '~/common/ui/Can'
import { ButtonSolid } from '~/common/ui/Buttons'

const IDLE_TIMEOUT = 5000

type Props = {
  deployment: Deployment
  isCurrent: boolean
  isNew: boolean
  locked: boolean
  activeVersion?: string | undefined
}

const ComponentBuild = (props: Props) => {
  const { activeVersion, deployment, isCurrent, isNew, locked } = props
  const { component } = React.useContext(RootStore)
  const [status, setStatus] = React.useState<Status>('IDLE')

  const handleDeploy = () => {
    if (deployment) {
      setStatus('PENDING')
      component
        .deploy(deployment.id)
        .then(() => setStatus('SUCCESS'))
        .catch(() => setStatus('ERROR'))
        .finally(() => setTimeout(() => setStatus('IDLE'), IDLE_TIMEOUT))
    }
  }

  const rootClasses = className({
    't-component-build py-4 px-4 border-b border-slate-200': true,
    'bg-slate-200': isCurrent,
  })

  return (
    <div className={rootClasses}>
      <div className="grid grid-cols-5">
        <div className="t-column-info col-span-3">
          <div className="">
            <ComponentTime
              className="text-slate-900 font-semibold"
              time={deployment.modifiedTS}
              isNew={isNew}
            />
            by {deployment.commitAuthor || 'Unknown'}
          </div>
          <ComponentVersion type="GitHash" version={deployment.version} />
          {isNew && (
            <ComponentDiff
              activeVersion={activeVersion || undefined}
              targetVersion={deployment.version}
              repoName={deployment.pipelineSlug}
            />
          )}
          <p className="text-slate-400 pt-2">{deployment.changeLog}</p>
        </div>

        <div className="t-column-current">
          {isCurrent && <span>Current</span>}
          {isNew && <span className="text-green-600">New</span>}
        </div>

        {!locked && (
          <div className="t-column-deploy">
            <Can
              requiredRole={Role.deployer}
              yes={
                <ButtonSolid
                  onClick={handleDeploy}
                  disabled={status === 'PENDING'}
                >
                  {(function () {
                    if (status === 'PENDING') return 'Loading...'
                    if (status === 'ERROR') return 'Error :('
                    if (status === 'SUCCESS') return 'Deployed :)'
                    return isCurrent ? 'Redeploy' : 'Deploy'
                  })()}
                </ButtonSolid>
              }
              no={
                <ButtonSolid disabled>
                  {isCurrent ? 'Redeploy' : 'Deploy'}
                </ButtonSolid>
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ComponentBuild
