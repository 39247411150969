import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import { Loading } from '~/common/ui/Loading'
import { usePolicies, Policy, PolicyAttachment } from '~/common/api'
import { Organisation } from '~/stores/organisations/types'
import { DateText } from '~/common/ui/DateText'
import { Status } from '~/common/ui/Status'

const headers = [
  { title: 'Name' },
  { title: 'Description' },
  { title: 'Organisation Attached' },
  { title: 'Policy Type' },
  { title: 'Policy Owner' },
  { title: 'Status' },
  { title: 'Modified' },
]

const CustomerPolicyList: React.FC<RouteComponentProps<{ id: string }>> =
  observer(({ history, match }) => {
    const { organisation: organisationStore } = React.useContext(RootStore)

    const organisationId = (organisationStore.current as Organisation)?.id || ''

    const {
      policies = [],
      policy_attachments = [],
      loading,
      error,
    } = usePolicies(organisationId || '')

    console.log({ policies, loading, error })

    if (!organisationStore.current) {
      return <NoResults title="No organisation selected" />
    }

    if (organisationStore.current.status === 'SUSPENDED') {
      return <NoResults title="Organisation is suspended" />
    }

    if (policies.length === 0) {
      return (
        <React.Fragment>
          <NoResults title="No policies found" />
        </React.Fragment>
      )
    }

    if (organisationStore.isLoading || loading) return <Loading />

    const OrganisationAttachment = ({ policy }: { policy: Policy }) => {
      const value = policy.OrganisationAttachment || []
      if (value.length === 0) {
        return <Fragment>FALSE</Fragment>
      }
      return <Fragment>TRUE</Fragment>
    }

    const PolicyAttachments = ({
      policy,
      policy_attachments,
    }: { policy: Policy; policy_attachments: PolicyAttachment[] }) => {
      return (
        <Fragment>
          {policy_attachments
            .filter((item) => item.PolicyId === policy.Id)
            .map((item) => (
              <div
                key={item.Id}
                style={{ paddingTop: '5px', paddingBottom: '5px' }}
              >
                <span style={{ display: 'block' }}>
                  Type: {item.PolicyAttachmentType}
                </span>
                <span style={{ display: 'block' }}>
                  Target: {item.OrganisationalUnitId || item.AccountId}
                </span>
                <span style={{ display: 'block' }}>Status: {item.Status}</span>
              </div>
            ))}
        </Fragment>
      )
    }

    return (
      <Fragment>
        <table className="DataTable">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={header.title}>{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {policies.map((policy, index) => (
              <Fragment key={policy.Id}>
                <tr key={policy.Id} className="borderNone">
                  <td colSpan={6}>
                    <p className="font-bold">Id:</p>
                    {policy.Id} ({policy.AwsId})
                  </td>
                </tr>
                <tr key={policy.Id} className="borderNone">
                  <td>{policy.Name}</td>
                  <td>{policy.Description}</td>
                  <td>
                    <OrganisationAttachment policy={policy} />
                  </td>
                  <td>{policy.PolicyType}</td>
                  <td>{policy.PolicyOwner}</td>
                  <td>
                    <Status status={policy.Status} />
                  </td>
                  <td>
                    <DateText dateString={policy.ModifiedTS} />
                  </td>
                </tr>
                <tr key={policy.Id + 'extra'}>
                  <td colSpan={2} className="border-bottom-none">
                    <p className="font-bold">Attachments:</p>

                    <PolicyAttachments
                      policy={policy}
                      policy_attachments={policy_attachments}
                    />
                  </td>
                  <td colSpan={5}>
                    <p className="font-bold">Policy:</p>
                    <textarea className="w-full">
                      {JSON.stringify(policy.Content)}
                    </textarea>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </Fragment>
    )
  })

export default CustomerPolicyList
