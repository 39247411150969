import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { OrgId, User } from './types'
import { RootStore } from '~/stores/root/RootStore'
import { Status } from '~/common/api/apiLegacy'
import { UserApi } from './UserApi'

export default class UserStore {
  root: RootStore
  api: UserApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: UserApi, endpoint = '/users') {
    makeObservable(this, {
      status: observable,
      users: observable,
      fetch: action,
      sendVerifyEmail: action,
      isLoading: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  status: Status = Status.Idle

  // Store Users under Organisation ID in a Map in order to facilitate
  // faster lookups when switching between organisations
  users: ObservableMap<OrgId, User[]> = observable.map()

  fetch = async (orgId: string, force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(`${this.endpoint}/${orgId}`, force)
      this.users.set(orgId, res.data.users)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  sendVerifyEmail = (orgId: string, userId: string) => {
    return this.api.sendVerifyEmail(
      `${this.endpoint}/send-verify-email`,
      orgId,
      userId,
    )
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  list = (orgId: OrgId) => {
    return this.users.get(orgId) || []
  }
}
