import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import * as storage from '~/common/utils/auth/localStorage'
import { getQueryStringValue } from '~/common/utils/URL'
import { BusyFullWidth } from '~/common/ui/Loading'
import { routes } from '~/routes'

/**
 * Cognito will bounce us back to this route post-login
 *  - we check the state variable matches what we've got stored in localStorage
 *      (NOTE: this variable is defined over at oAuthURL.ts)
 */
export interface Props extends RouteComponentProps<{}> {}

// TODO: move this to withAmplifyAuth, then prop drill it down via AuthWrapper
async function processStateVariable() {
  const stateFromUrl = getQueryStringValue('state')

  if (!stateFromUrl) {
    throw new Error('Missing auth state variable')
  }
  const stateFromLocalStorage = storage.getAuthState()

  if (stateFromLocalStorage !== stateFromUrl) {
    console.error('Auth state mismatch', {
      stateFromUrl,
      stateFromLocalStorage,
    })

    throw new Error('Auth state values do not match')
  }
}

// When Cognito bounces back with user's tokens, we need to process them
async function processLogin(onFail: Function) {
  try {
    await processStateVariable()

    // Note that we don't need to send them anywhere, AuthWrapper is listening ;)
  } catch (error) {
    console.error('processLogin failed: ', error)

    onFail()
  }
}

/**
 * Cognito will bounce back to this screen after login
 */
const Component = (props: Props) => {
  const onFail = () => props.history.push(routes.auth.SIGN_OUT)
  useEffect(
    () => {
      processLogin(onFail)
    },
    [], // run once
  )

  return <BusyFullWidth />
}

export const PageLoginProcess = withRouter(Component)

export default PageLoginProcess
