import { observer } from 'mobx-react-lite'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'

type Props = {
  activeVersion: string | undefined
  targetVersion: string
  repoName: string
}

function constructUrl(
  activeVersion: string,
  targetVersion: string,
  repoName: string,
): string {
  // Construct GitHub diff URL
  return `https://github.com/jumacloud/${repoName}/compare/${activeVersion}...${targetVersion}`
}

const ComponentDiff = observer((props: Props) => {
  const { activeVersion, targetVersion, repoName } = props

  if (!activeVersion) return null

  const diffUrl = constructUrl(activeVersion, targetVersion, repoName)

  return (
    <a href={diffUrl} target="_blank" rel="noreferrer" title="Diff">
      Diff{' '}
      <ArrowTopRightOnSquareIcon
        className="inline-block"
        style={{
          fontSize: '1rem',
          marginBottom: -'3px',
          height: '16px',
          width: '16px',
        }}
      />
    </a>
  )
})

export default ComponentDiff
