import { ButtonOutline } from '~/common/ui/Buttons'
import { put } from '~/common/api/apiLegacy'
import { Loading } from '~/common/ui/Loading'
import { useAsync } from '~/common/hooks'
import { Notice } from '~/common/ui/Notices'
import { customerDetailLink } from '../../routes'
import { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from '~/common/ui/Modals'
import { TextInput } from '~/common/ui/Forms'

export const ConfigureSecurityHubModal = ({
  customerId,
  organisationId,
  orgAlias = '',
}: {
  customerId: string
  organisationId: string
  orgAlias: string
}) => {
  // TODO: we really should use URL params for these IDs
  //   const match = useRouteMatch<{ id?: string }>()
  //   const customerId = match.params.id || ''
  const history = useHistory()
  const handleClose = () =>
    history.push(customerDetailLink(customerId, 'services'))

  const [orgAliasInput, setOrgAlias] = useState('')

  // console.log({ orgAliasInput, orgAlias, organisationId, customerId })

  const orgAliasMatches = orgAlias !== '' && orgAliasInput === orgAlias

  const configureSecurityHub = async () =>
    await put(
      `/organisations/${
        organisationId || ''
      }/configuration/security-hub/configure`,
    )

  const { execute, status, error, value } = useAsync(
    configureSecurityHub,
    false,
  )

  return (
    <Modal
      aria-describedby="configure-security-hub-description"
      aria-labelledby="configure-security-hub-title"
      isOpen={true}
      onClose={handleClose}
    >
      <ModalTitle id="configure-security-hub-title">
        Reconfigure Security Hub
      </ModalTitle>

      {status === 'pending' && (
        <ModalContent>
          <Loading />
        </ModalContent>
      )}

      {status === 'success' && (
        <ModalContent>
          <p title={JSON.stringify(value.data)}>
            ✅ Reconfiguring Security Hub...
          </p>

          <p>Please close this dialog and check back later for updates</p>
        </ModalContent>
      )}

      {(status === 'idle' || status === 'error') && (
        <Fragment>
          <ModalContent>
            <Notice>
              You are making changes to the organisation{' '}
              <span
                title={`Org ID: ${organisationId}`}
                className="font-semibold"
              >
                {orgAlias}
              </span>
              's security configuration
            </Notice>

            {status === 'error' && <Notice variant="Error">{error}</Notice>}

            <p className="font-semibold my-2">
              Please type the Organisation's Alias to confirm this change:
            </p>

            <TextInput
              className="w-full"
              onChange={(event) => setOrgAlias(event.target.value)}
              placeholder="Organisation Alias"
              value={orgAliasInput}
            />
          </ModalContent>

          <ModalFooter>
            <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>

            <ButtonOutline
              onClick={execute}
              variant={orgAliasMatches ? 'Danger' : 'Secondary'}
              disabled={!orgAliasMatches}
            >
              Reconfigure
            </ButtonOutline>
          </ModalFooter>
        </Fragment>
      )}
    </Modal>
  )
}
