import React from 'react'
import CustomerListContainer from '~/widgets/CustomerList'
import RootStore from '~/stores/root/RootStore'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Can, { Role } from '~/common/ui/Can'
import { routes } from '../routes'
import Page from '~/common/ui/Page'
import { ButtonOutline } from '~/common/ui/Buttons'

const PageCustomerList: React.FC<RouteComponentProps> = ({ history }) => {
  const { billingroot } = React.useContext(RootStore)

  React.useEffect(() => {
    billingroot.fetch('UNUSED')
  }, [])

  const numActiveBillingRoots = billingroot.poolList().length

  const actions = (
    <div>
      <Can
        requiredRole={Role.deployer}
        yes={
          <div>
            {numActiveBillingRoots > 0 ? (
              <ButtonOutline
                onClick={() => history.push(routes.provision.ADDCUST)}
              >
                + New Customer
              </ButtonOutline>
            ) : null}
          </div>
        }
        no={
          <div>
            <ButtonOutline
              disabled={true}
              title="You aren't allowed to create new customers"
            >
              + New Customer
            </ButtonOutline>
          </div>
        }
      />
    </div>
  )

  return (
    <Page title="Customers" actions={actions}>
      <CustomerListContainer />
    </Page>
  )
}

export default withRouter(PageCustomerList)
