import * as RadixSwitch from '@radix-ui/react-switch'

type SwitchProps = {
  checked: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
}

export const Switch = (props: SwitchProps) => {
  return (
    <RadixSwitch.Root
      className="Switch"
      checked={props.checked}
      disabled={props.disabled}
      onCheckedChange={props.onChange}
    >
      <RadixSwitch.Thumb className="SwitchThumb" />
    </RadixSwitch.Root>
  )
}
