import { Form, Formik, FormikProps, useField } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import RootStore from '~/stores/root/RootStore'
import {
  FIELDS,
  FIELD_HELPER_TEXTS,
  VERIFICATION_FORM_INITIAL_STATE,
} from '~/common/constantsProvision'
import {
  computeFieldStyleProps,
  validateRequired,
} from '~/common/utils/provision'
import { Notice } from '~/common/ui/Notices'
import { ButtonOutline } from '~/common/ui/Buttons'
import { FormTextInput } from '~/common/ui/Forms'

type Props = FormikProps<typeof VERIFICATION_FORM_INITIAL_STATE>

const VerificationForm: React.FC<Props> = observer(({ handleSubmit }) => {
  const [
    awsAccountIdField,
    { error: awsAccountIdError, touched: awsAccountIdTouched },
  ] = useField({ name: FIELDS.AWS_ACCOUNT_ID, validate: validateRequired })

  const [
    customerNameField,
    { error: customerNameError, touched: customerNameTouched },
  ] = useField({ name: FIELDS.CUSTOMER_NAME })

  const [
    accountEmailField,
    { error: accountEmailError, touched: accountEmailTouched },
  ] = useField({ name: FIELDS.AWS_ACCOUNT_EMAIL })

  return (
    <Form onSubmit={handleSubmit} className="pt-100">
      <FormTextInput
        id={awsAccountIdField.name}
        label="Account ID"
        required={false}
        {...awsAccountIdField}
        {...computeFieldStyleProps(
          awsAccountIdError,
          awsAccountIdTouched,
          FIELD_HELPER_TEXTS[FIELDS.AWS_ACCOUNT_ID],
        )}
      />

      <FormTextInput
        id={customerNameField.name}
        label="Customer Name"
        required={false}
        {...customerNameField}
        {...computeFieldStyleProps(
          customerNameError,
          customerNameTouched,
          FIELD_HELPER_TEXTS[FIELDS.CUSTOMER_NAME],
        )}
      />

      <FormTextInput
        id={accountEmailField.name}
        label="Account Email"
        required={false}
        {...accountEmailField}
        {...computeFieldStyleProps(
          accountEmailError,
          accountEmailTouched,
          FIELD_HELPER_TEXTS[FIELDS.AWS_ACCOUNT_EMAIL],
        )}
      />

      <div className="flex justify-end">
        <ButtonOutline type="submit">Verify</ButtonOutline>
      </div>
    </Form>
  )
})

const ProvisionVerification: React.FC<RouteComponentProps> = ({ history }) => {
  const { provision } = React.useContext(RootStore)
  const [result, setResult] = React.useState('')

  const handleSubmit = async (
    values: typeof VERIFICATION_FORM_INITIAL_STATE,
  ) => {
    const payload = {
      AwsAccountId: values.awsAccountId,
      // Explictly null for the API
      CustomerName: values.customerName ? values.customerName : null,
      AwsAccountEmail: values.awsAccountEmail ? values.awsAccountEmail : null,
    }

    const res = await provision.verify_onboarding(payload)
    if (res.status === 200 && res.data.result) {
      setResult(res.data.result)
    }
  }

  return (
    <Fragment>
      {result ? (
        <Notice className="my-3" variant="Success">
          <p>
            {result}. Results are relayed to the Stax Slack channel{' '}
            <a
              href="slack://channel?team=T27H23T2M&id=C034SFF4YER"
              target="__blank"
              className="bold"
            >
              #onboarding-findings
            </a>
          </p>
        </Notice>
      ) : (
        <Notice className="my-3">
          <p>
            This initiates a background process where the results are relayed to
            the Stax Slack channel{' '}
            <a
              href="slack://channel?team=T27H23T2M&id=C034SFF4YER"
              target="__blank"
              className="bold"
            >
              #onboarding-findings
            </a>
          </p>
        </Notice>
      )}
      <Formik
        validateOnMount={true}
        initialValues={VERIFICATION_FORM_INITIAL_STATE}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(formikProps) => <VerificationForm {...formikProps} />}
      </Formik>
    </Fragment>
  )
}

export default ProvisionVerification
