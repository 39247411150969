import React from 'react'
import { observer } from 'mobx-react-lite'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import { WorkloadCatalogue } from '~/stores/catalogue/types'
import { Status } from '~/common/ui/Status'
import { Loading } from '~/common/ui/Loading'
import { ClickToCopy } from '~/common/ui/ClickToCopy'

const headers = [{ title: 'Name' }, { title: 'Status' }, { title: 'ID' }]

const gettableRows = (items: WorkloadCatalogue[]) => {
  return items.map((item) => ({
    key: item.id,
    cells: [
      item.name,
      <Status key="status" status={item.status} />,
      <React.Fragment key={item.id}>
        {item.id} <ClickToCopy text={item.id} />
      </React.Fragment>,
    ],
  }))
}

const CustomerCatalogue = observer(() => {
  const { catalogue, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    if (organisation.current) {
      catalogue.fetch(organisation.current.id)
    }
  }, [organisation.current])

  if (!organisation.current) {
    return <NoResults title="No organisation selected" />
  }

  const items = catalogue.list(organisation.current.id)
  const rows = gettableRows(items)

  if (organisation.isLoading || catalogue.isLoading) return <Loading />

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.cells.map((cell, index) => (
              <td key={index}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
})

export default CustomerCatalogue
