import { observer } from 'mobx-react-lite'

type VersionType = 'GitHash' | 'SemVer'

type Props = {
  version: string
  type: VersionType
  truncation?: number
}

function parseVersion(
  version: string,
  type: VersionType,
  truncation: number,
): string {
  if (type === 'GitHash') {
    return version.substr(0, truncation)
  }
  return version
}

const ComponentVersion = observer((props: Props) => {
  const { version, type, truncation = 7 } = props

  const parsedVersion =
    version === '' ? 'unknown' : parseVersion(version, type, truncation)

  return <div className="py-1 text-xs">Version {parsedVersion}</div>
})

export default ComponentVersion
