import { observer } from 'mobx-react-lite'
import className from 'classnames'
import { Link } from 'react-router-dom'
import { StaxLogo } from '~/common/ui/StaxLogo'

type Props = {
  compact: boolean
}

const NavLogo = observer((props: Props) => {
  const { compact } = props

  const iconClasses = className({
    'fill-white': true,
    'w-8': compact,
    'w-10': !compact,
  })

  return (
    <Link to="/" className="flex justify-center items-center w-10" title="Juma">
      <StaxLogo className={iconClasses} />
    </Link>
  )
})

export default NavLogo
