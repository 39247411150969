import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import { Loading } from '~/common/ui/Loading'
import { useOrganisationalUnits } from '~/common/api'
import { Organisation } from '~/stores/organisations/types'
import { DateText } from '~/common/ui/DateText'
import { Status } from '~/common/ui/Status'

const headers = [
  { title: 'Id' },
  { title: 'Name' },
  { title: 'Parent' },
  { title: 'Type' },
  { title: 'Status' },
  { title: 'Modified' },
]

const CustomerOrganisationalUnitList: React.FC<
  RouteComponentProps<{ id: string }>
> = observer(({ history, match }) => {
  const { organisation: organisationStore } = React.useContext(RootStore)

  const organisationId = (organisationStore.current as Organisation)?.id || ''

  const {
    organisational_units = [],
    loading,
    error,
  } = useOrganisationalUnits(organisationId || '')

  console.log({ organisational_units, loading, error })

  if (!organisationStore.current) {
    return <NoResults title="No organisation selected" />
  }

  if (organisationStore.current.status === 'SUSPENDED') {
    return <NoResults title="Organisation is suspended" />
  }

  if (organisational_units.length === 0) {
    return (
      <React.Fragment>
        <NoResults title="No organisational units found" />
      </React.Fragment>
    )
  }

  if (organisationStore.isLoading || loading) return <Loading />

  return (
    <Fragment>
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {organisational_units.map((organisational_unit, index) => (
            <tr key={organisational_unit.Id}>
              <td>{organisational_unit.Id}</td>
              <td>{organisational_unit.Name}</td>
              <td>{organisational_unit.ParentOrganisationalUnitId}</td>
              <td>{organisational_unit.OrganisationalUnitType}</td>
              <td>
                <Status status={organisational_unit.Status} />
              </td>
              <td>
                <DateText dateString={organisational_unit.ModifiedTS} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
})

export default CustomerOrganisationalUnitList
