import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from '~/common/ui/Switch'
import { InlineControlAndLabel } from '~/common/ui/Forms'
import RootStore from '~/stores/root/RootStore'
import { Component } from '~/stores/components/types'
import Can, { Role } from '~/common/ui/Can'

type Props = {
  className?: string
  enabled: boolean
  component: Component
  disabled?: boolean
}

const ComponentDeploySwitch = observer(
  ({ enabled, disabled = false, ...restProps }: Props) => {
    const { component } = React.useContext(RootStore)
    const [isEnabled, setIsEnabled] = React.useState(enabled)

    const handleChange = (checked: boolean) => {
      setIsEnabled(checked)
      component.autodeploy(restProps.component, checked)
    }

    return (
      <Can
        requiredRole={Role.deployer}
        yes={
          <InlineControlAndLabel label="Auto Deploy">
            <Switch
              checked={isEnabled}
              disabled={disabled}
              onChange={handleChange}
            />
          </InlineControlAndLabel>
        }
        no={
          <InlineControlAndLabel label="Auto Deploy">
            <Switch checked={isEnabled} disabled={true} />
          </InlineControlAndLabel>
        }
      />
    )
  },
)

export default ComponentDeploySwitch
