import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import RootStore from '~/stores/root/RootStore'
import NoResults from '~/common/ui/NoResults'
import FactoryButton from '~/common/ui/FactoryButton'
import { Account } from '~/stores/accounts/types'
import Can, { Role } from '~/common/ui/Can'
import { Status } from '~/common/ui/Status'
import { ClickToCopy } from '~/common/ui/ClickToCopy'
import { StaxLogo } from '~/common/ui/StaxLogo'
import MaybeLogin from './CustomerAndOrgDetails/MaybeLogin'

const headers = [
  { key: 'ids', title: 'IDs' },
  { key: 'cost', title: 'Cost' },
  { key: 'status', title: 'Status' },
  { key: 'factoryVer', title: 'Factory Ver.' },
  { key: 'action', title: 'Action' },
  { key: 'awsLogin', title: 'AWS Login' },
]

const isBillingRoot = ({ billingAccountId }: { billingAccountId: string }) =>
  !billingAccountId

// If an account is either created by stax OR a billing root it is "Stax Managed"
const isStaxManaged = (account: Account): boolean => {
  return !!account.staxCreated || isBillingRoot(account)
}

const CustomerAccountList = observer(() => {
  const { account: accountStore, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    if (organisation.current) {
      accountStore.fetch(organisation.current.id)
    }
  }, [organisation.current])

  if (!organisation.current) {
    return <NoResults title="No organisation selected" />
  }

  const accounts = accountStore
    .list(organisation.current.id)
    .filter((account) => {
      if (isBillingRoot(account)) return false
      return true
    })

  const billingRoots = accountStore
    .list(organisation.current.id)
    .filter((account) => {
      if (isBillingRoot(account)) return true
      return false
    })

  const renderRow = (account: Account) => {
    const runSaga = () => accountStore.invokeSaga(account.id)

    const runDiscovery = () =>
      accountStore.invokeDiscoverySaga(
        account.organisationId,
        account.awsAccountId,
      )

    const runReonboard = () => accountStore.reonboard(account.id)

    const renderSagaButton = (disabled = false) => {
      if (account.status === 'ERROR') {
        if (account.assuranceState === 'NONE') {
          // discovery failure
          return renderDiscoveryButton(disabled)
        }
        if (
          account.assuranceState !== 'NONE' &&
          account.assuranceState !== 'UPDATING'
        ) {
          // onboarding failure
          return (
            <FactoryButton
              onClick={runReonboard}
              key="factory-re-onboard"
              disabled={disabled}
            >
              Re-onboard
            </FactoryButton>
          )
        }
      }

      const assuranceButtonDisabled =
        account.status === 'DISCOVERED' || account.status === 'ONBOARDING'

      if (!assuranceButtonDisabled) {
        return (
          <FactoryButton
            disabled={disabled}
            key="factory"
            onClick={runSaga}
            title="Runs Account Assurance for this Account"
          >
            Run Assurance
          </FactoryButton>
        )
      } else {
        return renderDiscoveryButton(disabled)
      }
    }

    const renderDiscoveryButton = (disabled = false) => {
      const buttonDisabled = account.status !== 'DISCOVERED' || disabled

      return (
        <Fragment>
          <FactoryButton
            onClick={runDiscovery}
            key="factory-re-discovery"
            disabled={buttonDisabled}
          >
            Run Discovery
          </FactoryButton>
        </Fragment>
      )
    }

    return (
      <tr key={account.id}>
        <td>
          {account.name}
          {isStaxManaged(account) && (
            <span title="Stax-managed Account">
              <StaxLogo className="ml-2 w-4 inline-block" />
            </span>
          )}
        </td>
        <td>
          <div>
            <small>
              AWS ID: {account.awsAccountId}{' '}
              <ClickToCopy text={account.awsAccountId} />
            </small>
          </div>
          <div>
            <small>
              Stax ID: {account.id} <ClickToCopy text={account.id} />
            </small>
          </div>
        </td>
        <td>{account.latestCost ? `$${account.latestCost}` : '$0.00'}</td>
        <td>
          <Status status={account.status} />
        </td>
        <td>
          <small>
            {account.factoryVersion}{' '}
            <ClickToCopy text={account.factoryVersion} />
          </small>
        </td>
        <td style={{ width: '15%' }}>
          <Can
            requiredRole={Role.deployer}
            yes={renderSagaButton()}
            no={renderSagaButton(true)}
          />
        </td>
        <td style={{ width: '15%' }}>
          <MaybeLogin {...{ account, organisation }} />
        </td>
      </tr>
    )
  }

  return (
    <table className="DataTable">
      {billingRoots.length > 0 && (
        <React.Fragment>
          <thead>
            <tr>
              <th>Billing roots</th>
              {headers.map((header, i) => (
                <th
                  key={header.key}
                  style={{ minWidth: i === 0 ? '20%' : 'none' }}
                >
                  {header.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{billingRoots.map(renderRow)}</tbody>
          <br />
          <br />
        </React.Fragment>
      )}
      <thead>
        <tr>
          <th>Member accounts</th>
          {headers.map((header, i) => (
            <th key={header.key} style={{ minWidth: i === 0 ? '20%' : 'none' }}>
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{accounts.map(renderRow)}</tbody>
    </table>
  )
})

export default CustomerAccountList
